import * as React from 'react';
import styled from 'styled-components'
import { connect } from 'react-redux';
import { ApplicationState } from '../../store';
import * as WHSDataReportStore from '../../store/WHSDataReportReducer';
import _ from 'lodash';
import { RouteComponentProps } from 'react-router';
import Select from '../Select'
import Button from '../Button'
import DatePicker from "react-datepicker";
import * as endpoints from '../constants/endpoints'
import Cookies from 'universal-cookie';
import loader from '../../images/spinner.gif'
import HeadlineComponent from "../Headline";
import { Row, Col } from 'react-grid-system';
import i18next from "i18next";

//import DatepickerWrap from '../DatepickerWrap'
const cookies = new Cookies();


type WHSDataReportProps =
  WHSDataReportStore.WHSDataReportState &
  typeof WHSDataReportStore.actionCreators &
  RouteComponentProps<{}>;

class NewReport extends React.PureComponent<WHSDataReportProps> {
  state = {
    dateFrom: '',
    dateTo: '',
    showOvarlayLoader: false
  }

  constructor(props) {
    super(props);
  }

  componentDidMount() {

  }

  componentDidUpdate() {

  }

  handleDateSelect = (value, propName) => {
      this.setState({[propName]: value})
  }
  handleDateChange = () => null

  handleSubmit = () => {
    this.setState({showOvarlayLoader: true})
    const url = `${endpoints.POC_MAPPINGS_LOGS}`

    fetch(url, {
      method: 'POST',
      headers: {
        'Accept': '*/*',
        'Content-Type': 'application/json-patch+json',
        'Authorization': `Bearer ${cookies.get('rawtoken')}`
      },
      body: JSON.stringify({
       fromDate:  this.state.dateFrom,
       toDate: this.state.dateTo
      })
    })
    .then((res) => {
      return res
    })
    .then((res) => res.blob())
    .then((blob) => {
      this.setState({showOvarlayLoader: false})
      var url = window.URL.createObjectURL(blob);
      var a = document.createElement("a");
      document.body.appendChild(a);
      a.href = url;
      a.download = `POC_mapping_logs.xls`;
      a.click();
      window.URL.revokeObjectURL(url);
    })
  }










  emitDate = (value, type) => {
      if (type === 'dateFrom') {
        _.set(this.state, 'dateFrom', value);
      } else {
        _.set(this.state, 'dateTo', value);
      }
    }



  public render() {
    const TableHead = styled.div`
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-end;

      > * {
        margin: 0 8px;

        &:first-child {
        margin-left: 0;
        }

        &:last-child {
        margin-right: 0;
        }
      }
    `

    return (
      <div className="report-page">
        {this.state.showOvarlayLoader === true && (
        <div className="d-flex align-items-center justify-content-center ovarlay-loader" style={{minHeight: '300px'}}>
            <div className="text-center">
              Downloading ... <br/>
              <img src={loader} alt="" />
            </div>
        </div>
        )}

        {this.state.showOvarlayLoader === false && (
          <div>
            <HeadlineComponent disableMargin={false}>{i18next.t('POC mapping history')}</HeadlineComponent>

            <Row>
              <Col md={5}>
                <DatePicker
                  placeholderText={i18next.t('placeholder_selectDate')}
                  selected={this.state.dateFrom}
                  onSelect={(v) => this.handleDateSelect(v, 'dateFrom')} //when day is clicked
                  onChange={this.handleDateChange} //only when value has changed
                  dateFormat="dd/MM/yyyy"
                />
              </Col>

              <Col md={5}>
                <DatePicker
                  placeholderText={i18next.t('placeholder_selectDate')}
                  selected={this.state.dateTo}
                  onSelect={(v) => this.handleDateSelect(v, 'dateTo')} //when day is clicked
                  onChange={this.handleDateChange} //only when value has changed
                  dateFormat="dd/MM/yyyy"
                />
              </Col>

              <Col md={2}>
                <Button
                  variant='outlined'
                  onClick={this.handleSubmit}
                  style={{minHeight: '38px', width: '100%'}}
                  disabled={this.state.dateFrom === '' || this.state.dateTo === ''}
                >
                  EXCEL
                </Button>
              </Col>
            </Row>
          </div>
        )}



      </div>
    )
  }

}
export default connect(
  (state: ApplicationState) => state.whsDataReports,
  WHSDataReportStore.actionCreators
)(NewReport);
