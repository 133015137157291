import * as React from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from '../../store';
import * as SKUStore from '../../store/SKUReducer';
import { RouteComponentProps } from 'react-router';
import $ from 'jquery';
import Button from '../Button'
import Cookies from 'universal-cookie';
import { EditIcon, DeleteIcon, AddIcon, ViewIcon } from '../../svg'
import styled from 'styled-components'
//import Select from "../Select";
import Table from '../Table';
import HeadlineComponent from "../Headline";
import * as endpoints from '../constants/endpoints'
import _ from 'lodash';
import ModalSkuMapEdit from '../Modal/ModalSkuMapEdit'
import ModalProductHistory from '../Modal/ModalProductHistory'
import loader from '../../images/spinner.gif'
import i18next from "i18next";
const cookies = new Cookies();

type SKUProps =
	SKUStore.SKUState &
	typeof SKUStore.actionCreators &
	RouteComponentProps<{}>;

class CreateMappingSKU extends React.PureComponent<SKUProps> {
	state = {
		//closeModal: undefined,
		selectedDepo: null,
		depots: [],
		depotsLoaded: false,
		// data and settings for distributor
		dataSKU: [],
		dataSKU_org: [],
		selectedSKUId: '',
		//distributorCustomerCollection: [],
		SKUCollectionIds: [],
		skuCollection: [],
		SKUTotalSize: 0,
		SKUItemsPerPage: 10,
		SKUCurrentPage: 1,
		isSKULoading: true,
		SKUOrderBy: 'name',
		SKUOrderDirection: 'asc',
		SKUFilters: {
	      depotName: "",
	      productCode: "",
	      productDescription: "",
	      productDateCreated: "",
	      productHasFreeCase: "",
	      calcHasFreeCase: "",
	      skuName: "",
	      skuMappingDateUpdated: "",
	      skuMappingFromDate: "",
	      skuMappingToDate: "",
	      searchValue:""
		},
		isModalOpen: false,
		modalAction: '',
		isModalHistoryOpen: false,
		selectedProductId: '',
		isFreeCase: '',
		showOvarlayLoader: false
	}

	constructor(props) {
		super(props);
		this.onDepoSelect = this.onDepoSelect.bind(this);
		this.getSKUMapping = this.getSKUMapping.bind(this)
		this.onSorterClick = this.onSorterClick.bind(this);
		this.paginationUpdated = this.paginationUpdated.bind(this)
		this.emitSelectedIds = this.emitSelectedIds.bind(this)
		this.emitFilterValue = this.emitFilterValue.bind(this)
		this.emitSelectedDepo = this.emitSelectedDepo.bind(this)
		this.getRowDataById = this.getRowDataById.bind(this)
		this.openModal = this.openModal.bind(this)
		this.closeModal = this.closeModal.bind(this)
		this.onFilterSKU = _.debounce(this.getSKUMapping , 2000)
		this.emitExportSKUData = this.emitExportSKUData.bind(this)
	}

	componentDidMount() {
		this.getAllDepo()
		this.getSKUMapping()
	}

	openModal(action) {
	    this.setState({ isModalOpen: true, modalAction: action });
	    this.removeBodyCss();
	};

	closeModal(shouldUpdate) {
		if (shouldUpdate) {
			this.setState({
				isModalOpen: false,
				isModalHistoryOpen: false,
				selectedSKUId: ''
			}, () => {this.getSKUMapping()});
		} else {
			this.setState({ isModalOpen: false, isModalHistoryOpen: false });
		}
		this.removeBodyCss();
	};

	toggleHistoryModal = (id) => {
		if (id.includes('-isFreeCase-')) {
			const res = id.split("-isFreeCase-")
			const isFreeCase = (res[1] === 'true')
			const itemIndex = this.state.dataSKU_org.findIndex( item => item.product.id === parseInt(res[0]) && item.product.hasFreeCase === isFreeCase );

			this.setState({
				selectedProductId: res[0],
				isFreeCase: this.state.dataSKU_org[itemIndex].product.hasFreeCase,
				isModalHistoryOpen: true
			});
		} else {
			const itemIndex = this.state.dataSKU_org.findIndex( item => item.product.id === parseInt(id) );

			this.setState({
				selectedProductId: id,
				isFreeCase: this.state.dataSKU_org[itemIndex].product.hasFreeCase,
				isModalHistoryOpen: true
			});
		}
	}

  	removeBodyCss() {
    	document.body.classList.add('no_padding');
  	};

	getAllDepo = () => {
        const url = `${endpoints.MANAGE_DISTRIBUTOR_DEPOTS_ENDPOINT}`
        fetch(url, {
              method: 'get',
              headers: {
                'Accept': '*/*',
                'Content-Type': 'application/json-patch+json',
                'Authorization': `Bearer ${cookies.get('rawtoken')}`
              }
            }).then(res => res.json())
              .then(res => {
                  this.setState({depots: res, depotsLoaded: true})
              });
    }

	getSKUMapping = () => {
		this.setState({isSKULoading: true})
		const url = `${endpoints.SKU_MAPPINGS_ENDPOINT}/Page`

		fetch(url, {
			  method: 'post',
			  headers: {
				'Accept': '*/*',
				'Content-Type': 'application/json-patch+json',
				'Authorization': `Bearer ${cookies.get('rawtoken')}`
			  },
			  body: JSON.stringify({
	            page:this.state.SKUCurrentPage - 1,
	            pageSize:this.state.SKUItemsPerPage,
	            orderBy: this.state.SKUOrderBy, // name
	            orderDirection: this.state.SKUOrderDirection, // asc
	            filters: {
					depotName: this.state.SKUFilters.depotName,
					productCode: this.state.SKUFilters.productCode,
					productDescription: this.state.SKUFilters.productDescription,
					productDateCreated: this.state.SKUFilters.productDateCreated,
					calcHasFreeCase: this.state.SKUFilters.productHasFreeCase,
					skuName: this.state.SKUFilters.skuName,
					skuMappingDateUpdated: this.state.SKUFilters.skuMappingDateUpdated,
					skuMappingFromDate: this.state.SKUFilters.skuMappingFromDate,
					skuMappingToDate: this.state.SKUFilters.skuMappingToDate,
					searchValue: this.state.SKUFilters.searchValue,
	            }

			  })
			}).then(res => res.json())
			  .then(res => {
			  	const slicedData = res.data.slice(0, 1000)

			  	const updateIdData = res.data.map( x => {
			  		x.id = `${x.product.id}-isFreeCase-${x.product.hasFreeCase}`;

				  	return x
				})

			  	const updatedResponse = res.data.map(item => {
			  		return {
			  			depotName: item.depotName,
			  			productCode: item.product ? item.product.code : '',
			  			productDescription: item.product ? item.product.description : '',
			  			productDateCreated: item.product ? new Date(item.product.dateCreated).toLocaleDateString("en-GB") : '',
			  			productHasFreeCase: item.product.hasFreeCase.toString() ,
			  			skuName: item.sku ? item.sku.name : 'Missing mapping',
			  			skuMappingDateUpdated: item.skuMapping && item.skuMapping.dateUpdated !== null ? new Date(item.skuMapping.dateUpdated).toLocaleDateString("en-GB") : '',
			  			skuMappingFromDate: item.skuMapping && item.skuMapping.fromDate !== null ? new Date(item.skuMapping.fromDate).toLocaleDateString("en-GB") : '',
			  			skuMappingToDate: item.skuMapping && item.skuMapping.toDate !== null ? new Date(item.skuMapping.toDate).toLocaleDateString("en-GB") : '',
			  			id: `${item.product.id}-isFreeCase-${item.product.hasFreeCase}`,
			  			//id: item.product.id,
			  			history: item.history ? item.history : 'true'
			  		}
			  	})

			  	this.setState({
			  		SKUTotalSize: res.dataTotalSize,
			  		dataSKU: updatedResponse,
			  		dataSKU_org: updateIdData,
			  		isSKULoading: false
			  	})


			  })
	}

	getSKUTableSettings = () => {
		return {
			tableID: 'DistributorCustomers',
			keyField: 'depotId',
			wrapperType: 'DistributorCustomers',
			headers: {
				'Accept': '*/*',
				'Content-Type': 'application/json-patch+json',
				'Authorization': `Bearer ${cookies.get('rawtoken')}`
			},
			tableColumns: [
				{ key: 'depotName', title: i18next.t('depot_name'), isFilterable: true, sortable: true },
				{ key: 'productCode', title: i18next.t('code'), isFilterable: true, sortable: true },
				{ key: 'productDescription', title: i18next.t('description'), isFilterable: true, sortable: true },
				{ key: 'productDateCreated', title: i18next.t('date_create_product'), isFilterable: true, sortable: true},
				{ key: 'productHasFreeCase', title: i18next.t('has_free_case'), isFilterable: true, sortable: true },
				{ key: 'skuName', title: i18next.t('kamenitza_product'), isFilterable: true, sortable: true },
				{ key: 'skuMappingDateUpdated', title: i18next.t('last_update'), isFilterable: true, sortable: true },
				{ key: 'skuMappingFromDate', title: i18next.t('from'), isFilterable: true, sortable: true },
				{ key: 'skuMappingToDate', title: i18next.t('to'), isFilterable: true, sortable: true },
				{ key: 'actions', title: i18next.t('actions'), filter: null, isFilterable: false }
			],
			tableCells: ["depotName", "productCode", "productDescription", "productDateCreated", "productHasFreeCase", "skuName", "skuMappingDateUpdated", "skuMappingFromDate", "skuMappingToDate"],
			"searchSuccess":true,
			// "dataTotalSize":52093,
			"dateCreated":"0001-01-01T00:00:00",
			"dateUpdated":"0001-01-01T00:00:00"
		}
	};

	// edit this
	onSorterClick(label, direction, type) {

		this.setState({
    		SKUOrderBy: label,
    		SKUOrderDirection: direction
    	}, () => this.getSKUMapping())
    }

    getRowDataById = (id) => {
		// use the unmodified data
		const collection = this.state.dataSKU_org;

		// fix to search product id
		const indexOf = collection.findIndex(item => item.id === id);

		return collection[`${indexOf}`]
	}

    // edit this
    emitSelectedIds(array, type) {
		if ( array.length === 0 ) {
			this.setState({
    			selectedSKUId: '',
    			SKUCollectionIds: [],
    			skuCollection: []
    		})
		} else {
			const newCollection = []
			array.map(item => {
				newCollection.push(this.getRowDataById(item))
			})

    		this.setState({
    			selectedSKUId: [...array],
    			SKUCollectionIds: [...array],
    			skuCollection: newCollection
    		})
		}
    }

    emitFilterValue(value, key) {
		_.set(this.state, `SKUFilters.${key}`, value);
		_.set(this.state, 'SKUCurrentPage', 1);

		this.onFilterSKU()
    }

    emitSelectedDepo(obj) {
    	_.set(this.state, 'selectedDepo', obj);
    }

    onFilterSKU() {
    	this.getSKUMapping()
    }

    paginationUpdated(itemsPerTable, currentPage) {
		this.setState({
			SKUItemsPerPage: itemsPerTable,
			SKUCurrentPage: currentPage
		}, () => this.getSKUMapping())
    }

	deleteMapping = () => {
    	const url = `${endpoints.SKU_MAPPINGS_ENDPOINT}/${this.state.skuCollection[0].skuMapping.id}`

    	if (window.confirm("Сигурен ли си, че искаш да изтриеш")) {
			fetch(url, {
				method: 'delete',
				headers: {
					'Accept': '*/*',
					'Content-Type': 'application/json-patch+json',
					'Authorization': `Bearer ${cookies.get('rawtoken')}`
				},
			}).then(res => {
				if (res.status === 200) {
					this.setState({
						selectedSKUId: '',
		    			SKUCollectionIds: [],
		    			skuCollection: []
					}, () => this.getSKUMapping())

				}
			})
    	}
    }

	onDepoSelect(depoName) {
		this.setState({selectedDepo: depoName});
	}

	emitExportSKUData() {
    	this.setState({showOvarlayLoader: true})
        const url = `${endpoints.SKU_MAPPINGS_ENDPOINT}/exportCsv`

        fetch(url, {
            method: 'POST',
            headers: {
                'Accept': '*/*',
                'Content-Type': 'application/json-patch+json',
                'Authorization': `Bearer ${cookies.get('rawtoken')}`
            },
            body: JSON.stringify({
            	filters: {
            		depotName: this.state.SKUFilters.depotName,
            		productCode: this.state.SKUFilters.productCode,
            		productDescription: this.state.SKUFilters.productDescription,
            		productDateCreated: this.state.SKUFilters.productDateCreated,
            		productHasFreeCase: this.state.SKUFilters.productHasFreeCase,
            		calcHasFreeCase: this.state.SKUFilters.calcHasFreeCase,
            		skuName: this.state.SKUFilters.skuName,
            		skuMappingDateUpdated: this.state.SKUFilters.skuMappingDateUpdated,
            		skuMappingFromDate: this.state.SKUFilters.skuMappingFromDate,
            		skuMappingToDate: this.state.SKUFilters.skuMappingToDate,
            		searchValue: this.state.SKUFilters.searchValue,
            	}
            })
        })
        .then((res) => res.blob())
        .then((blob) => {
        	this.setState({showOvarlayLoader: false})
        	var url = window.URL.createObjectURL(blob);
        	var a = document.createElement("a");
        	document.body.appendChild(a);
        	a.href = url;
        	a.download = `SKU_data.csv`;
        	a.click();
        	window.URL.revokeObjectURL(url);
        })
    }

	public render() {
		const TableHead = styled.div`
			width: 100%;
			display: flex;
			align-items: center;
			justify-content: flex-end;

			> * {
				margin: 0 8px;

				&:first-child {
				margin-left: 0;
				}

				&:last-child {
				margin-right: 0;
				}
			}
		`

		TableHead.Field = styled.div`
			width: ${({ size }) => size === 'large' ? '200px' : '120px'};
		`
		// new
		const PageWrapper = styled.div`
			button.disabled { opacity: 0.6; pointer-events: none;}

			.search { padding-top: 3px; }
			.ovarlay-loader { position: fixed; top: 0; left: 0; width: 100%; height: 100%; background: rgba(0,0,0,.15); z-index: 12;}
		`

		return (
			<div id='sku-mappings-container'>
				<PageWrapper>
					<div id='poc-mappings-container'>
						<HeadlineComponent disableMargin={false}>Create SKU Mapping</HeadlineComponent>

						{this.state.depotsLoaded && (
							<Table
								type="sku"
								title="SKU"
								tableSettings={this.getSKUTableSettings()}
								data={this.state.dataSKU}
								onSorterClick={this.onSorterClick}
								allowMultipleSelection={true}
								paginationUpdated={this.paginationUpdated}
								totalItems={this.state.SKUTotalSize ? this.state.SKUTotalSize : 0}
								currentPage={this.state.SKUCurrentPage}
								itemsPerPage={this.state.SKUItemsPerPage}
								isLoading={this.state.isSKULoading}
								emitSelectedIds={this.emitSelectedIds}
								emitFilterValue={this.emitFilterValue}
								emitSelectedDepo={this.emitSelectedDepo}
								filters={this.state.SKUFilters}
								currentSelectedFilter={this.state.SKUOrderBy}
								currentSortingDirection={this.state.SKUOrderDirection}
								selectedRows={this.state.SKUCollectionIds}
								selectedDepo={this.state.selectedDepo}
								showMainAutocomplete={true}
								depots={this.state.depots}
								depotsLoaded={this.state.depotsLoaded}
								commentUrl={'/comments/sku/'}
								showActionCell={true}
								showCommentsBtn={true}
								toggleHistoryModal={this.toggleHistoryModal}
								emitExportData={this.emitExportSKUData}
							/>
						)}

						{this.state.showOvarlayLoader === true && (
		                  <div className="d-flex align-items-center justify-content-center ovarlay-loader" style={{minHeight: '300px'}}>
		                  		<div className="text-center">
		                  			Downloading ... <br/>
		                  			<img src={loader} alt="" />
		                  		</div>
		                  </div>
		                  )}

						<div className="d-flex align-items-center justify-content-end">
							<div className="actions d-flex justify-content-end pt-4 pb-4">
								<Button
									icon={AddIcon}
									size='large'
									variant='outlined'
									color='primary'
									id="create-sku-btn"
									onClick={() => this.openModal('create')}
									className={`
										ml-3
										${this.state.selectedSKUId !== '' && this.state.skuCollection.every(item => item.skuMapping === null) ? 'active': 'disabled'}
									` }
								>Create</Button>

								<Button
									icon={EditIcon}
									size='large'
									variant='outlined'
									id="edit-sku-btn"
									onClick={() => this.openModal('edit')}
									className={`
										ml-3
										${ this.state.skuCollection.length && this.state.skuCollection.length === 1 && this.state.skuCollection.every(item => item.skuMapping !== null)  ? 'active': 'disabled'}
									` }
								>Edit</Button>

								<Button
									icon={DeleteIcon}
									size='large'
									variant='outlined'
									color='danger'
									id="delete-map-btn"
									onClick={this.deleteMapping}
									className={`
										ml-3
										${ this.state.skuCollection.length === 1 && this.state.skuCollection[0].skuMapping !== null  ? 'active': 'disabled'}
									`
								}
								>Delete</Button>

								{/*<Button
									icon={ViewIcon}
									size='large'
									variant='outlined'
									color='primary'
									id="view-mapping-history"
									className={`
										ml-3
										${this.state.selectedSKUId !== '' ? 'active': 'disabled'}
									`
								}
								>View Session History</Button>*/}

							</div>
						</div>

						{this.state.selectedSKUId && this.state.isModalOpen === true && (
							<ModalSkuMapEdit
								selectedPocMapId={this.state.selectedSKUId}
								collection={this.state.skuCollection}
								isOpen={this.state.isModalOpen}
								closeModal={this.closeModal}
								selectedRows={this.state.skuCollection}
								modalAction={this.state.modalAction}
							/>
						)}

						{ this.state.isModalHistoryOpen === true && (
							<ModalProductHistory
								selectedProductId={this.state.selectedProductId}
								isFreeCase={this.state.isFreeCase}
								isOpen={this.state.isModalHistoryOpen}
								closeModal={this.closeModal}
							/>
						)}
					</div>
				</PageWrapper>
			</div>
		)
	}
}
export default connect(
	(state: ApplicationState) => state.sku,
	SKUStore.actionCreators
)(CreateMappingSKU);
