import * as React from "react";

import { Route } from "react-router";

import "ag-grid-community/styles/ag-grid.css";
import { ApplicationState } from "store";

import "ag-grid-community/styles/ag-theme-balham.css";
import Comments from "./components/Comments/Comments";
import Dashboard from "./components/Dashboard/Dashboard";
import DataLoad from "./components/DataLoad/DataLoad";
import DeleteDtrSales from "./components/Deletions/DeleteDtrSales";
import DeleteMappingSKU from "./components/Deletions/DeleteMappingSKU";
import DeleteSalesRows from "./components/Deletions/DeleteSalesRows";
import EditDistributorDepotsForm from "./components/Distributors/EditDistributorDepotsForm";
import EditDistributorForm from "./components/Distributors/EditDistributorForm";
import ManageDistributorDepotForm from "./components/Distributors/ManageDistributorDepotForm";
import ManageDistributors from "./components/Distributors/ManageDistributors";
import FetchData from "./components/FetchData";
import Layout from "./components/Layout";
import OrderDetails from "./components/Orders/OrderDetails";
import Orders from "./components/Orders/Orders";
import CreateMappingPOC from "./components/POC/CreateMappingPOC";
import MappingHistoryPOC from "./components/POC/MappingHistoryPOC";
import DepotRawData from "./components/Reports/DepotRawData";
import GeneratingSalesReport from "./components/Reports/GeneratingSalesReport";
import POCMappingHistory from "./components/Reports/POCMappingHistory";
import ReceivedSalesFiles from "./components/Reports/ReceivedSalesFiles";
import ReceivedSalesFilesDetails from "./components/Reports/ReceivedSalesFilesDetails";
import SKUMappingHistory from "./components/Reports/SKUMappingHistory";
import UploadedFilesReport from "./components/Reports/UploadedFilesReport";
import WHSDataReport from "./components/Reports/WHSDataReport";
import WHSStockReport from "./components/Reports/WHSStockReport";
import CreateMappingSKU from "./components/SKU/CreateMappingSKU";
import EditTenantForm from "./components/Tenants/EditTenantForm";
import ManageTenants from "./components/Tenants/ManageTenants";
import AddUserForm from "./components/Users/AddUserForm";
import AdminTenantAdminList from "./components/Users/AdminTenantAdminList";
import EditUserForm from "./components/Users/EditUserForm";
import LoginForm from "./components/Users/LoginForm";
import PasswordRecoveryForm from "./components/Users/PasswordRecoveryForm";
import UsersList from "./components/Users/UsersList";
import ProtectedRoute from "./helpers/ProtectedRoute";
import "bootstrap/dist/css/bootstrap.min.css";
import "font-awesome/css/font-awesome.css";
import "react-chat-elements/dist/main.css";

import { connect } from "react-redux";

import * as AuthenticationStore from "./store/AuthenticationReducer";

import Cookies from "universal-cookie";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";

import MonthlyRawSalesReport from "./components/Reports/MonthlyRawSalesReport";

import Products from "components/Products/Products";
import { USER_ROLES } from "components/Utils/actions";
import Pallets from "components/Pallets/Pallets";
import Packaging from "components/Packaging/Packaging";
import CreateOrder from "components/MpOrder/CreateOrder/CreateOrder";
import OrderHistory from "components/MpOrder/OrderHistory/OrderHistory";
import UploadProductsData from "components/UploadProductsData/UploadProductsData";
import MarketplaceReports from "components/Reports/MarketplaceReports";
import AccessDeniedPage from "components/Utils/AccessDeniedPage";
import FinancialInfo from "components/FinancialInfo/FinancialInfo";
const cookies = new Cookies();

class App extends React.PureComponent<any> {
  componentDidMount() {
    if (cookies.get("rawtoken")) {
      this.props.authenticationActions.changeAuthentication(true);
    }
  }

  render() {
    return (
      <Layout>
        <Route exact path="/" component={LoginForm} />
        <Route path="/login" component={LoginForm} />
        <Route path="/passwordRecovery" component={PasswordRecoveryForm} />
        <Route path="/fetch-data/:startDateIndex?" component={FetchData} />
        <ProtectedRoute
          path="/dashboard"
          component={Dashboard}
          roles={["TenantAdmin"]}
        />
        <ProtectedRoute
          path="/edit-user/:userId?"
          component={EditUserForm}
          roles={["Administrator", "TenantAdmin"]}
        />
        <ProtectedRoute
          path="/create-sku-mapping"
          component={CreateMappingSKU}
          roles={["TenantAdmin"]}
        />
        <ProtectedRoute
          path="/sku-mapping-deletion"
          component={DeleteMappingSKU}
          roles={["TenantAdmin"]}
        />
        <ProtectedRoute
          path="/row-from-sales-table-deletion"
          component={DeleteSalesRows}
          roles={["TenantAdmin"]}
        />
        <ProtectedRoute
          path="/dtr-sales-deletion"
          component={DeleteDtrSales}
          roles={["TenantAdmin"]}
        />
        <ProtectedRoute
          path="/create-poc-mapping"
          component={CreateMappingPOC}
          roles={["TenantAdmin", "TenantSupport", "Distributor"]}
        />
        <ProtectedRoute
          path="/mapping-history-poc"
          component={MappingHistoryPOC}
          roles={["TenantAdmin", "TenantSupport", "Distributor"]}
        />
        <ProtectedRoute
          path="/orders"
          component={Orders}
          roles={[
            "TenantAdmin",
            "TenantSupport",
            "TenantSupervisor",
            "Distributor",
          ]}
        />
        <ProtectedRoute
          path="/order/:orderId?"
          component={OrderDetails}
          roles={[
            "TenantAdmin",
            "TenantSupport",
            "TenantSupervisor",
            "Distributor",
          ]}
        />
        <ProtectedRoute
          path="/users"
          component={UsersList}
          roles={["TenantAdmin"]}
        />
        <ProtectedRoute
          path="/add-user"
          component={AddUserForm}
          roles={["Administrator", "TenantAdmin"]}
        />
        <ProtectedRoute
          path="/manage-admin-tenant-admin"
          component={AdminTenantAdminList}
          roles={["Administrator"]}
        />
        <ProtectedRoute
          path="/manage-tenants"
          component={ManageTenants}
          roles={["Administrator"]}
        />
        <ProtectedRoute
          path="/edit-tenant/:tenantId?"
          component={EditTenantForm}
          roles={["Administrator"]}
        />
        <ProtectedRoute
          path="/add-tenant"
          component={EditTenantForm}
          roles={["Administrator"]}
        />
        <ProtectedRoute
          path="/manage-distributors"
          component={ManageDistributors}
          roles={["TenantAdmin", "CustomerService"]}
        />
        <ProtectedRoute
          path="/edit-distributor/:distributorId?"
          component={EditDistributorForm}
          roles={["TenantAdmin", "CustomerService"]}
        />
        <ProtectedRoute
          path="/edit-distributor-depots/:distributorId?"
          component={EditDistributorDepotsForm}
          roles={["TenantAdmin", "CustomerService"]}
        />
        <ProtectedRoute
          path="/distributor-depots/distributorId:distributorId?&distributorName:distributorName?&tenantId:tenantId?/manage-distributor-depot/:depotId?"
          component={ManageDistributorDepotForm}
          roles={["TenantAdmin", "CustomerService"]}
        />
        <ProtectedRoute
          path="/add-distributor"
          component={EditDistributorForm}
          roles={["TenantAdmin"]}
        />
        <ProtectedRoute
          path="/received-sales-files-report"
          component={ReceivedSalesFiles}
          roles={["TenantAdmin"]}
        />
        <ProtectedRoute
          path="/received-sales-files-report-details/:distributorFileId"
          component={ReceivedSalesFilesDetails}
          roles={["TenantAdmin"]}
        />
        <ProtectedRoute
          path="/depot-raw-data-report"
          component={DepotRawData}
          roles={["TenantAdmin"]}
        />
        <ProtectedRoute
          path="/whs-data-report"
          component={WHSDataReport}
          roles={["TenantAdmin", "TenantSupervisor", "TenantSupport"]}
        />
        <ProtectedRoute
          path="/generating-sales-report"
          component={GeneratingSalesReport}
          roles={["TenantAdmin", "TenantSupervisor"]}
        />
        <ProtectedRoute
          path="/whs-stock-report"
          component={WHSStockReport}
          roles={["TenantAdmin", "TenantSupervisor"]}
        />
        <ProtectedRoute
          path="/uploaded-files-report"
          component={UploadedFilesReport}
          roles={["TenantAdmin"]}
        />
        <ProtectedRoute
          path="/poc-mapping-history"
          component={POCMappingHistory}
          roles={["TenantAdmin", "TenantSupervisor", "TenantSupport"]}
        />
        <ProtectedRoute
          path="/sku-mapping-history"
          component={SKUMappingHistory}
          roles={["TenantAdmin", "TenantSupervisor", "TenantSupport"]}
        />
        <ProtectedRoute
          path="/monthly-raw-sales-report"
          component={MonthlyRawSalesReport}
          roles={["TenantAdmin", "TenantSupervisor"]}
        />
        <ProtectedRoute
          path="/marketplace-reports"
          component={MarketplaceReports}
          roles={[
            USER_ROLES.TENANT_ADMIN,
            USER_ROLES.TENANT_SUPERVISOR,
            USER_ROLES.CUSTOMER_SERVICE,
          ]}
        />
        <ProtectedRoute
          path="/products"
          component={Products}
          roles={[
            USER_ROLES.TENANT_ADMIN,
            USER_ROLES.TENANT_SUPERVISOR,
            USER_ROLES.CUSTOMER_SERVICE,
          ]}
        />
        <ProtectedRoute
          path="/pallets"
          component={Pallets}
          roles={[USER_ROLES.TENANT_ADMIN, USER_ROLES.CUSTOMER_SERVICE]}
        />
        <ProtectedRoute
          path="/access-denied"
          component={AccessDeniedPage}
          roles={[USER_ROLES.DISTRIBUTOR]}
        />
        <ProtectedRoute
          path="/create-order"
          component={CreateOrder}
          roles={[
            USER_ROLES.TENANT_ADMIN,
            USER_ROLES.TENANT_SUPERVISOR,
            USER_ROLES.CUSTOMER_SERVICE,
          ]}
        />
        <ProtectedRoute
          path="/upload-products-data"
          component={UploadProductsData}
          roles={[
            USER_ROLES.TENANT_ADMIN,
            USER_ROLES.ADMINISTRATOR,
            USER_ROLES.TENANT_SUPERVISOR,
            USER_ROLES.CUSTOMER_SERVICE,
          ]}
        />
        <ProtectedRoute
          path="/order-history"
          component={OrderHistory}
          roles={[
            USER_ROLES.TENANT_ADMIN,
            USER_ROLES.TENANT_SUPERVISOR,
            USER_ROLES.CUSTOMER_SERVICE,
          ]}
        />
        <ProtectedRoute
          path="/packaging"
          component={Packaging}
          roles={[USER_ROLES.TENANT_ADMIN, USER_ROLES.CUSTOMER_SERVICE]}
        />
        <ProtectedRoute
          path="/comments/:type/:id?"
          component={Comments}
          roles={[
            "TenantAdmin",
            "TenantSupport",
            "TenantSupervisor",
            "Distributor",
          ]}
        />
        <ProtectedRoute
          path="/data-load"
          component={DataLoad}
          roles={["DataLoader", "TenantAdmin"]}
        />
        <ProtectedRoute
          path="/financial-info"
          component={FinancialInfo}
          roles={[
            "TenantAdmin",
            "CustomerService",
            "TenantSupervisor",
            "Distributor",
          ]}
        />
      </Layout>
    );
  }
}

function mapStateToProps(state: ApplicationState) {
  return {
    authenticationState: state.authentication,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    authenticationActions: bindActionCreators(
      AuthenticationStore.actionCreators,
      dispatch
    ),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
