import React, { FunctionComponent, ReactNode } from "react";
import styled from "styled-components";
import DataTable from "react-redux-datatable";
import "react-redux-datatable/dist/styles.css";
import tableStyle from "../components/constants/tableStyle";
import Box from "./Box";

import configureStore from "../store/configureStore";
import { createBrowserHistory } from "history";
import Loader from "components/Loader";
const baseUrl = document
  .getElementsByTagName("base")[0]
  .getAttribute("href") as string;
const history = createBrowserHistory({ basename: baseUrl });
const store = configureStore(history);

const TableWrapper = styled.div`
  ${tableStyle}

  .react-bootstrap-table {
    max-height: 400px;
    overflow: auto;
    border-bottom: 1px solid ${({ theme }) => theme.colors.border} !important;
    margin: 0;
  }

  .filter-label {
    display: block;

    input {
      margin-top: 8px;
      margin-bottom: 4px;
      height: 34px;
      background: #fff;
    }
  }

  .table-button-menu {
    button {
      &:focus,
      &:active {
        outline: none !important;
      }
    }
  }

  .react-datatable {
    font-family: ${({ theme }) => theme.fonts.default};
  }

  .react-bootstrap-table .table-striped > tbody > tr {
    background-color: transparent;

    &:hover {
      background: ${({ theme }) => theme.colors.semiLight};
    }
  }

  .react-bs-table-sizePerPage-dropdown {
    margin-left: 20px;

    .btn {
      padding: 4px 14px;
      font-size: 10px;
      border: 1px solid #ebedf2;
      color: ${({ theme }) => theme.colors.dark};

      &:focus,
      &:active,
      &:hover {
        background: none;
        box-shadow: none;
        outline: none;
        color: ${({ theme }) => theme.colors.dark};
        border-color: #ebedf2;
      }

      &::after {
        position: relative;
        top: 2px;
        border-top-color: ${({ theme }) => theme.colors.dark};
      }
    }

    .caret {
      display: none;
    }
  }

  .react-bootstrap-table-pagination .dropdown-menu {
    bottom: auto;
    box-shadow: 0 0 50px 0 rgba(82, 63, 105, 0.15);
    width: 200px;
    border-radius: 0;
    border: 0;

    li {
      position: relative;
      border-bottom: 1px solid #f3f3f3;
      padding: 0;

      button {
        display: block;
        font-size: 11px;
        text-decoration: none;
        padding: 10px 15px;
        color: ${({ theme }) => theme.colors.dark};

        &:hover {
          background: #f7f8fa;
          color: ${({ theme }) => theme.colors.default};
        }
      }
    }
  }

  .row.react-bootstrap-table-pagination {
    margin: 0 !important;

    > * {
      padding: 0;
    }
  }

  .inner {
    overflow: visible !important;
  }

  .react-bs-table-search-form {
    width: 100%;
  }

  input {
    display: block;
    width: 100%;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #e6e6e6;
    font-size: 12px;
    padding: 0 12px;
    height: 34px;

    &:focus {
      box-shadow: none !important;
      outline: none;
      border-color: ${({ theme }) => theme.colors.default};
    }
  }

  .form-group {
    margin-bottom: 0;
  }

  .react-bs-table-tool-bar {
    padding-top: 15px;
    padding-bottom: 15px;

    .row > div {
      display: flex;
      align-items: center;
    }
  }

  .react-bootstrap-table td.react-bs-table-no-data {
    text-align: center;
    padding: 30px 0 !important;
    font-size: 14px;
    margin-top: 20px;
  }

  div.status_message {
    margin: 30px;
    padding: 12px 15px;
    font-size: 14px;
    font-family: ${({ theme }) => theme.fonts.default};
    background: ${({ theme }) => theme.colors.danger};
    color: #fff;
    box-shadow: none;
    border: 0;
    border-radius: 3px;

    &::after {
      display: none;
    }
  }

  .react-bootstrap-table-pagination > div > div {
    background: ${({ theme }) => theme.colors.default};
    color: #fff !important;
    font-size: 10px !important;
    padding: 4px 8px !important;
    border-radius: 2px !important;
    float: none !important;
    display: inline-block !important;
    margin-left: 20px !important;
    margin-top: 15px !important;
    margin-bottom: 15px !important;
  }
`;

interface Props {
  title?: string;
  buttons?: ReactNode;
  hasActions?: boolean;
  // All other props
  [x: string]: any;
  isLoading?: boolean;
}

const DataTableComponent: FunctionComponent<Props> = ({
  title,
  buttons,
  hasActions = false,
  isLoading,
  ...restProps
}) => (
  <TableWrapper {...{ hasActions }}>
    <>
      <Box {...{ title, buttons }}>
        <Loader isLoading={isLoading} />
        <DataTable store={store} {...restProps} />
      </Box>
    </>
  </TableWrapper>
);

export default DataTableComponent;
