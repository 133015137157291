import * as React from "react";
import { connect } from "react-redux";
import { ApplicationState } from "../../store";
import * as POCStore from "../../store/POCReducer";
import Button from "../Button";
import Cookies from "universal-cookie";
import { EditIcon, DeleteIcon } from "../../svg";

import TableWrapperHermesSku from "../TableWrapperHermesSku";

import _ from "lodash";
import * as endpoints from "../constants/endpoints";
import styled from "styled-components";
import DatepickerWrap from "../DatepickerWrap";

import { toast } from "react-toastify";
import i18next from "i18next";
import "react-datepicker/dist/react-datepicker.css";

const cookies = new Cookies();

interface Props {
  selectedPocMapId?: any;
  closeModal?: any;
  isOpen?: any;
  collection?: any;
  selectedRows?: any;
  modalAction?: any;
}

class ModalSkuMapEditActionWrap extends React.Component<Props> {
  state = {
    hasChanged: false,
    selectedHermesId: "",
    hermesCollectionIds: [],
    dateFrom: "",
    dateTo: "",
    disableBtn: false,
  };

  _isMounted = false;

  constructor(props) {
    super(props);

    this.createMapping = this.createMapping.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;

    if (this.props.collection[0].skuMapping !== null) {
      this.setState({
        dateFrom: this.props.collection[0].skuMapping.fromDate
          ? this.props.collection[0].skuMapping.fromDate
          : "",
        dateTo: this.props.collection[0].skuMapping.toDate
          ? this.props.collection[0].skuMapping.toDate
          : "",
      });
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  closeModal = (shouldUpdate) => {
    this.props.closeModal(shouldUpdate);
  };

  getHermesSkuTableSettings = () => {
    return {
      tableID: "HermesSKU",
      keyField: "hermesId",
      tableColumns: [
        {
          key: "name",
          title: i18next.t("name"),
          isFilterable: true,
          sortable: true,
        },
        {
          key: "packaging",
          title: i18next.t("packaging"),
          isFilterable: true,
          sortable: true,
        },
        {
          key: "qty",
          title: i18next.t("count"),
          isFilterable: false,
          sortable: false,
        },
        {
          key: "volume",
          title: i18next.t("volume"),
          isFilterable: false,
          sortable: false,
        },
      ],
      tableCells: ["name", "packaging", "quantity", "volume"],
    };
  };

  emitHermesId = (hermesId) => {
    _.set(this.state, "selectedHermesId", hermesId);
  };

  deleteMapping = () => {
    const url = `${endpoints.POC_MAPPINGS_ENDPOINT}/${this.props.selectedPocMapId}`;

    if (window.confirm(i18next.t("confirm_delete"))) {
      fetch(url, {
        method: "delete",
        headers: {
          Accept: "*/*",
          "Content-Type": "application/json-patch+json",
          Authorization: `Bearer ${cookies.get("rawtoken")}`,
        },
      }).then((res) => {
        if (res.status === 200) {
          this.setState(
            {
              hermesCollectionIds: [],
              hasChanged: true,
            },
            () => {
              this.closeModal(this.state.hasChanged);
            },
          );
        }
      });
    }
  };

  createMapping = () => {
    let action = null;
    let urlAddon = "";
    this.setState({
      disableBtn: true,
    });

    if (this.props.modalAction === "create") {
      action = "POST";
    } else {
      action = "PUT";
      urlAddon = `/${this.props.collection[0].skuMapping.id}`;
    }

    const url = `${endpoints.SKU_MAPPINGS_ENDPOINT}${urlAddon}`;

    let skuId;
    if (this.state.selectedHermesId === "") {
      skuId = this.props.collection[0].sku.id;
    } else {
      skuId = this.state.selectedHermesId;
    }

    const bodyCollection = this.props.collection.map((item, index) => {
      return {
        skuId: skuId,
        productId: this.props.collection[index].product.id,
        depotId: this.props.collection[index].product.distributorDepot.id,
        isFreeCase: this.props.collection[index].product.hasFreeCase,
        fromDate: this.state.dateFrom,
        toDate: this.state.dateTo,
      };
    });

    fetch(url, {
      method: action,
      headers: {
        Accept: "*/*",
        "Content-Type": "application/json-patch+json",
        Authorization: `Bearer ${cookies.get("rawtoken")}`,
      },
      body: JSON.stringify(bodyCollection),
    })
      .then((res) => {
        if (res.status === 200) {
          this.setState(
            {
              hasChanged: true,
            },
            () => {
              this.closeModal(this.state.hasChanged);
            },
          );

          toast.success(i18next.t("successful_mapping"));
        } else if (res.status === 409) {
          this.setState({
            disableBtn: false,
          });
          toast.error(i18next.t("mapping_conflict"));
        } else {
          this.setState({
            disableBtn: false,
          });
          toast.error(i18next.t("internal_server_error"));
        }
      })
  };

  emitDate = (value, type) => {
    if (type === "dateFrom") {
      _.set(this.state, "dateFrom", value);
    } else {
      _.set(this.state, "dateTo", value);
    }
  };

  public render() {
    const ModalWrapper = styled.div`
      .search {
        padding-top: 3px;
      }

      .actions {
        display: none;
      }
      .actions button {
        width: 33.33%;
      }
      .actions .react-datepicker-wrapper {
        width: 100%;
      }
    `;

    return (
      <ModalWrapper>
        <div className="actions d-flex align-items-start pt-4 pb-4">
          {this.props.modalAction === "edit" ? (
            <Button
              id="edit-map-btn"
              icon={EditIcon}
              size="large"
              variant="outlined"
              onClick={this.createMapping}
              className="w-50"
              disabled={this.state.disableBtn}
            >
              {i18next.t("edit_mapping")}
            </Button>
          ) : (
            <Button
              id="edit-map-btn"
              icon={EditIcon}
              size="large"
              variant="outlined"
              onClick={this.createMapping}
              className="w-50"
              disabled={this.state.disableBtn}
            >
              {i18next.t("create_mapping")}
            </Button>
          )}

          <DatepickerWrap
            dateType={"dateFrom"}
            currentDate={this.state.dateFrom}
            emitDate={this.emitDate}
          />
          <DatepickerWrap
            dateType={"dateTo"}
            currentDate={this.state.dateTo}
            emitDate={this.emitDate}
          />
        </div>

        <TableWrapperHermesSku
          title={i18next.t("hermes_sku")}
          tableSettings={this.getHermesSkuTableSettings()}
          selectedRows={this.props.selectedRows}
          emitHermesId={this.emitHermesId}
          selectedProductId={
            this.props.collection[0].sku ? this.props.collection[0].sku.id : 0
          }
        />
      </ModalWrapper>
    );
  }
}
export default connect(
  (state: ApplicationState) => state.poc,
  POCStore.actionCreators,
)(ModalSkuMapEditActionWrap);
