import { checkHttpStatus, parseJSON, DEFAULT_HEADERS } from '../Utils/actions';
import Cookies from 'universal-cookie';
import * as endpoints from '../constants/reportsEndpoints/reportsEndpoints'
import FileSaver from 'file-saver';

const cookies = new Cookies();

export function getRegions(callback, error) {
    //TODO CHANGE URL
    return fetch(endpoints.GET_DEPOTS_REGIONS_ENDPOINT, {
        method: 'get',
        headers: {'Accept': '*/*','Content-Type': 'application/json-patch+json','Authorization': `Bearer ${cookies.get('rawtoken')}`}
    })
        .then(checkHttpStatus)
        .then(parseJSON)
        .then(response => {
            try {
                callback(response);
            } catch (e) {
                error(e);
            }
        })
        .catch(e => {
            error(e);
        })
}

export function getDepotsForRegion(region, callback, error) {
    var url = endpoints.GET_DEPOTS_FOR_REGION_ENDPOINT + '?region=' + region;

    return fetch(url, {
        method: 'get',
        headers: {'Accept': '*/*','Content-Type': 'application/json-patch+json','Authorization': `Bearer ${cookies.get('rawtoken')}`}
    })
        .then(checkHttpStatus)
        .then(parseJSON)
        .then(response => {
            try {
                callback(response);
            } catch (e) {
                error(e);
            }
        })
        .catch(e => {
            error(e);
        })
}

export function getSalesFilesForDepot(depotId, callback, error) {
    var url = endpoints.GET_RECEIVED_SALES_FILES_FOR_DEPOT_ID_ENDPOINT;
    url += '&depotId=' + depotId;

    return fetch(url, {
        method: 'get',
        headers: {'Accept': '*/*','Content-Type': 'application/json-patch+json','Authorization': `Bearer ${cookies.get('rawtoken')}`}
    })
        .then(checkHttpStatus)
        .then(parseJSON)
        .then(response => {
            try {
                callback(response);
            } catch (e) {
                error(e);
            }
        })
        .catch(e => {
            error(e);
        })
}

export function getSalesFileEntries(receivedSalesFileId, callback, error) {
    var url = endpoints.GET_RECEIVED_SALES_FILE_ENTRIES_FOR_FILE_ID_ENDPOINT;
    url += '?distributorFileId=' + receivedSalesFileId;

    return fetch(url, {
        method: 'get',
        headers: {'Accept': '*/*','Content-Type': 'application/json-patch+json','Authorization': `Bearer ${cookies.get('rawtoken')}`}
    })
        .then(checkHttpStatus)
        .then(parseJSON)
        .then(response => {
            try {
                callback(response);
            } catch (e) {
                error(e);
            }
        })
        .catch(e => {
            error(e);
        })
}

export function downloadReceivedSalesFileReport(receivedSalesReportId, callback, error) {
    var url = endpoints.DOWNLOAD_RECEIVED_SALES_FILES_REPORT_ENDPOINT,
        fileName = '';
    url += '?distributorFileId=' + receivedSalesReportId;
    return fetch(url, {
        method: 'get',
        headers: {'Accept': '*/*','Content-Type': 'application/json-patch+json','Authorization': `Bearer ${cookies.get('rawtoken')}`}
    })
        .then(checkHttpStatus)
        .then(function (response) {
            var x = response.headers
                .get("content-disposition");
            fileName = response.headers
                .get("content-disposition")
                .split(';')[1]
                .split('=')[1];
            return response.blob();
        }).then(function (blob) {
            try {
                FileSaver.saveAs(blob, fileName);
                callback();
            } catch (e) {
                error(e);
            }
        })
        .catch(e => {
            error(e);
        })
}