import { checkHttpStatus, parseJSON, DEFAULT_HEADERS} from '../Utils/actions';
import Cookies from 'universal-cookie';
import * as endpoints from '../constants/reportsEndpoints/reportsEndpoints'

const cookies = new Cookies();

export function initializeSelectData(callback, error) {
    //TODO IMPLEMENT BASED ON REQUIREMENTS FOR GETTING REGIONS,MONTHS,YEARS DATA
    return fetch(endpoints.GET_DEPOTS_REGIONS_ENDPOINT, {
        method: 'get',
        headers: {'Accept': '*/*','Content-Type': 'application/json-patch+json','Authorization': `Bearer ${cookies.get('rawtoken')}`}
    })
        .then(checkHttpStatus)
        .then(parseJSON)
        .then(response => {
            try {
                callback(response);
            } catch (e) {
                error(e);
            }
        })
        .catch(e => {
            error(e);
        })
}

function timeout(ms, promise) {
    return new Promise(function (resolve, reject) {
        setTimeout(function () {
            reject(new Error("timeout"))
        }, ms)
        promise.then(resolve, reject)
    })
}

timeout(1000, fetch('/hello')).then(function (response) {
    // process response
}).catch(function (error) {
    // might be a timeout error
})

export function getDepotsForSelectedData(selectedData, callback, error) {
    var url = endpoints.GET_WHS_DATA_REPORT_ENDPOINT;

    return timeout(8888888, fetch(url, {
        method: 'POST',
        headers: { 'Accept': '*/*', 'Content-Type': 'application/json-patch+json', 'Authorization': `Bearer ${cookies.get('rawtoken')}` },
        body: JSON.stringify(selectedData)
    })).then(checkHttpStatus)
        .then(parseJSON)
        .then(response => {
            try {
                callback(response);
            } catch (e) {
                error(e);
            }
        })
        .catch(e => {
            error(e);
        })
        
}