export const LOGIN_USER_ENDPOINT =
  process.env.REACT_APP_API_ENDPOINT + "api/Login/Login";
export const SEND_VERIFICATION_CODE =
  process.env.REACT_APP_API_ENDPOINT + "api/User/ForgotPassword";
export const CHANGE_PASSWORD =
  process.env.REACT_APP_API_ENDPOINT + "api/User/ChangePassword";
export const GET_USERS_FOR_ADMIN_ENDPOINT =
  process.env.REACT_APP_API_ENDPOINT + "api/users/AdminUser/";
export const GET_LATEST_RAW_DATA =
  process.env.REACT_APP_API_ENDPOINT + "api/Reports/action/get-latest-raw-data";
export const DOWNLOAD_MONTHLY_REPORT =
  process.env.REACT_APP_API_ENDPOINT +
  "api/Reports/action/download-monthly-report/";
export const MANAGE_DISTRIBUTOR_DEPOTS_ENDPOINT =
  process.env.REACT_APP_API_ENDPOINT + "api/distributors/DistributorDepots/";
export const MANAGE_HERMES_DEPOTS_ENDPOINT =
  process.env.REACT_APP_API_ENDPOINT + "api/hermes/HermesDepot/";
export const GET_DEPOTS_FOR_TENANT_SUPERVISOR_ENDPOINT =
  process.env.REACT_APP_API_ENDPOINT + "api/users/TenantSupervisor/GetDepots/";
export const ASSOCIATE_DEPOTS_TO_TENANT_SUPERVISOR_ENDPOINT =
  process.env.REACT_APP_API_ENDPOINT + "api/users/TenantSupervisor/AddDepots";
export const DEPO_EDIT_IS_ACTIVE =
  process.env.REACT_APP_API_ENDPOINT +
  "api/distributors/DistributorDepots/change-is-active";
export const DISTRIBUTOR_EDIT_IS_ACTIVE =
  process.env.REACT_APP_API_ENDPOINT +
  "api/distributors/Distributor/change-is-active";
export const GET_USER =
  process.env.REACT_APP_API_ENDPOINT + "api/users/AdminUser/";
export const GET_USERS_FOR_TENANT_ADMIN_ENDPOINT =
  process.env.REACT_APP_API_ENDPOINT + "api/users/TenantAdmin/";
export const USER_EDIT_IS_ACTIVE =
  process.env.REACT_APP_API_ENDPOINT + "api/users/TenantAdmin/change-is-active";
export const SKU_MAPPINGS_ENDPOINT =
  process.env.REACT_APP_API_ENDPOINT + "api/hermes/SkuMappings";
export const POC_MAPPINGS_LOGS =
  process.env.REACT_APP_API_ENDPOINT + "api/Reports/action/getpocmappinglogs";
export const SKU_MAPPINGS_LOGS =
  process.env.REACT_APP_API_ENDPOINT + "api/Reports/action/getskumappinglogs";
export const POC_MAPPINGS_ENDPOINT =
  process.env.REACT_APP_API_ENDPOINT + "api/hermes/PocMappings";
export const POC_MAPPINGS_WITH_INFO_ENDPOINT =
  process.env.REACT_APP_API_ENDPOINT + "api/hermes/PocMappings/GetAllWithInfo";
export const DISTRIBUTOR_CUSTOMERS_ENDPOINT =
  process.env.REACT_APP_API_ENDPOINT + "api/distributors/DistributorCustomers";
export const GET_COUNT_UNMAPPED_ITEMS =
  process.env.REACT_APP_API_ENDPOINT +
  "api/hermes/SkuMappings/GetSkuMappingsWithInfoAdditionalData";
export const GET_POC_UNMAPPED_ITEMS_COUNT =
  process.env.REACT_APP_API_ENDPOINT +
  "api/distributors/DistributorCustomers/GetUnmappedCount";
export const HERMES_POC_ENDPOINT =
  process.env.REACT_APP_API_ENDPOINT + "api/hermes/HermesPoc";
export const DISTRIBUTOR_PRODUCTS_ENDPOINT =
  process.env.REACT_APP_API_ENDPOINT + "api/distributors/DistributorProducts";
export const DISTRIBUTOR_PRODUCT_GET_MAPPING_HISTORY_ENDPOINT =
  process.env.REACT_APP_API_ENDPOINT +
  "api/distributors/DistributorProducts/GetMappingHistory?id=";
export const DISTRIBUTOR_DISTRIBUTOR_SALES =
  process.env.REACT_APP_API_ENDPOINT + "api/distributors/DistributorSales";
export const HERMES_SKU_ENDPOINTS =
  process.env.REACT_APP_API_ENDPOINT + "api/hermes/HermesSku";
export const HERMES_GET_ALL_ORDERS_ENDPOINT =
  process.env.REACT_APP_API_ENDPOINT + "api/hermes/HermesOrder/GetAllOrders";
export const HERMES_GET_ORDER_DETAILS_ENDPOINT =
  process.env.REACT_APP_API_ENDPOINT + "api/hermes/HermesOrderDetail/";
export const HERMES_DOWNLOAD_ORDER_ENDPOINT =
  process.env.REACT_APP_API_ENDPOINT +
  "api/hermes/HermesOrder/DownloadOrderXml";
export const MANAGE_TENANTS_ENDPOINT =
  process.env.REACT_APP_API_ENDPOINT + "api/ManageTenants/";
export const MANAGE_HERMES_SUPPLIERS_ENDPOINT =
  process.env.REACT_APP_API_ENDPOINT + "api/hermes/HermesSupplier/";
export const MANAGE_DISTRIBUTORS_ENDPOINT =
  process.env.REACT_APP_API_ENDPOINT + "api/distributors/Distributor/";
export const MANAGE_COMMENTS_ENDPOINT =
  process.env.REACT_APP_API_ENDPOINT + "api/Comments";
export const MANAGE_PRODUCT_COMMENTS_ENDPOINT =
  process.env.REACT_APP_API_ENDPOINT +
  "api/Comments/GetCommentsForDistributorProduct";
export const MANAGE_CUSTOMER_COMMENTS_ENDPOINT =
  process.env.REACT_APP_API_ENDPOINT +
  "api/Comments/GetCommentsForDistributorCustomer";
export var userEditUrlByRoleId = {
  1: process.env.REACT_APP_API_ENDPOINT + "api/users/AdminUser/",
  2: process.env.REACT_APP_API_ENDPOINT + "api/users/TenantAdmin/",
  3: process.env.REACT_APP_API_ENDPOINT + "api/users/TenantSupport/",
  4: process.env.REACT_APP_API_ENDPOINT + "api/users/TenantSupervisor/",
  5: process.env.REACT_APP_API_ENDPOINT + "api/users/DistributorUser/",
  6: process.env.REACT_APP_API_ENDPOINT + "api/users/DataLoader/",
  7: process.env.REACT_APP_API_ENDPOINT + "api/users/CustomerService/",
};
export const loadDataFiles = {
  1: process.env.REACT_APP_API_ENDPOINT + "api/DataLoad/LoadAopSellIn",
  2: process.env.REACT_APP_API_ENDPOINT + "api/DataLoad/LoadDbpGtoPerHl",
  3:
    process.env.REACT_APP_API_ENDPOINT +
    "api/DataLoad/LoadMonthlyTargetsPerDistributor",
  4:
    process.env.REACT_APP_API_ENDPOINT +
    "api/DataLoad/LoadMonthlyTargetsPerPoc",
  5: process.env.REACT_APP_API_ENDPOINT + "api/DataLoad/LoadPmdHermes",
  6:
    process.env.REACT_APP_API_ENDPOINT + "api/DataLoad/LoadSkuMappingHermesSap",
};
export const DATA_LOAD_HISTORY =
  process.env.REACT_APP_API_ENDPOINT + "api/DataLoad/Page";

export const MANAGE_ERRORS =
  process.env.REACT_APP_API_ENDPOINT + "api/ErrorLog/page";
export const HermesExports =
  process.env.REACT_APP_API_ENDPOINT + "api/HermesExports/page";
export const GET_MP_DISTRIBUTORS =
  process.env.REACT_APP_API_ENDPOINT +
  "api/marketplace/MpSupplyEntities/GetMpDistributorsWithSapPayerId";
export const GET_DISTRIBUTOR_FIN_DATA =
  process.env.REACT_APP_API_ENDPOINT +
  "api/MpFinancialInfo/GetDistributorFinData";
