import React, { useMemo } from "react";
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the Data Grid
import "ag-grid-community/styles/ag-theme-quartz.css"; // Optional Theme applied to the Data Grid

import { ColDef } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import i18next from "i18next";

import {
  COLUMN_TYPE_NAMES,
  getGridDefaultProps,
} from "constants/agGridConstants";

type Props = {
  distributorFinancialDataDetails: DistributorFinancialDataDetails[];
};

const FinancialInfoTable = ({ distributorFinancialDataDetails }: Props) => {
  const colDefs = useMemo<ColDef<DistributorFinancialDataDetails>[]>(
    () => [
      { field: "productType", headerName: i18next.t("fi_table_product_type") },
      {
        field: "documentType",
        headerName: i18next.t("fi_table_document_type"),
      },
      { field: "documentNo", headerName: i18next.t("fi_table_document_no") },
      {
        field: "documentDate",
        headerName: i18next.t("fi_table_document_date"),
        type: COLUMN_TYPE_NAMES.DATE_COLUMN,
      },
      {
        field: "dueDate",
        headerName: i18next.t("fi_table_due_date"),
        type: COLUMN_TYPE_NAMES.DATE_COLUMN,
      },
      {
        field: "due",
        headerName: i18next.t("fi_table_due"),
      },
      {
        field: "inTerm",
        headerName: i18next.t("fi_table_in_term"),
      },
      {
        field: "balance",
        headerName: i18next.t("fi_table_balance"),
      },
    ],
    []
  );

  if (!distributorFinancialDataDetails) {
    return null;
  }

  return (
    <div style={{ height: 520, marginTop: 30 }}>
      <AgGridReact
        rowData={distributorFinancialDataDetails}
        columnDefs={colDefs}
        {...getGridDefaultProps()}
      />
    </div>
  );
};

export default FinancialInfoTable;
