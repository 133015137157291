import {
  getDistributorFinData,
  getMpDistributors,
} from "components/Actions/distributorsActions";
import Loader from "../Loader";
import React, { useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";
import DistributorSelector from "./components/DistributorSelector";
import FinancialInfoCards from "./components/FinancialInfoCards";
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the Data Grid
import "ag-grid-community/styles/ag-theme-quartz.css"; // Optional Theme applied to the Data Grid

import FinancialInfoTable from "./components/FinancialInfoTable";

const FinancialInfo = () => {
  const [distributors, setDistributors] = useState<Distributor[]>();
  const [isLoadingDistributors, setIsLoadingDistributors] = useState(true);
  const [selectedDistributorId, setSelectedDistributorId] =
    useState<Distributor["id"]>();
  const [distributorFinancialData, setDistributorFinancialData] =
    useState<DistributorFinancialData>();
  const [isLoadingFinancialData, setIsLoadingFinancialData] = useState(true);

  useEffect(() => {
    setIsLoadingDistributors(true);
    getMpDistributors(
      (response) => {
        setDistributors(response);
      },
      (error) => {
        toast.error(error.response.statusText ?? "Error");
      },
      () => {
        setIsLoadingDistributors(false);
      }
    );
  }, []);

  useEffect(() => {
    if (!selectedDistributorId) return;

    setIsLoadingFinancialData(true);

    getDistributorFinData(
      selectedDistributorId,
      (response: DistributorFinancialData) => {
        setDistributorFinancialData(response);
      },
      (error) => {
        toast.error(error.response.statusText ?? "Error");
      },
      () => {
        setIsLoadingFinancialData(false);
      }
    );
  }, [selectedDistributorId]);

  return (
    <>
      <Loader isLoading={isLoadingDistributors} />

      <DistributorSelector
        distributors={distributors}
        isLoading={isLoadingDistributors}
        setSelectedDistributorId={setSelectedDistributorId}
      />

      <FinancialInfoCards
        isLoadingFinancialData={isLoadingFinancialData}
        distributorFinancialData={distributorFinancialData}
      />

      {distributorFinancialData && (
        <FinancialInfoTable
          distributorFinancialDataDetails={distributorFinancialData.details}
        />
      )}
    </>
  );
};

export default FinancialInfo;
