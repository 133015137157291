import React from "react";

import { Flex } from "antd";
import Card from "components/Card/Card";
import i18next from "i18next";

type Props = {
  isLoadingFinancialData: boolean;
  distributorFinancialData: DistributorFinancialData;
};

const FinancialInfoCards = ({
  isLoadingFinancialData,
  distributorFinancialData,
}: Props) => {
  if (isLoadingFinancialData) return null;

  return (
    <Flex vertical style={{ width: "100%" }}>
      <Flex style={{ width: "100%", gap: 20 }}>
        <Card
          title={i18next.t("ttl_debt")}
          value={distributorFinancialData.inTermTotal}
        />
        <Card
          title={i18next.t("ttl_debt_due")}
          value={distributorFinancialData.dueTotal}
        />
        <Card
          title={i18next.t("ttl_balance")}
          value={distributorFinancialData.balanceTotal}
        />
      </Flex>
    </Flex>
  );
};

export default FinancialInfoCards;
