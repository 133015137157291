import * as React from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import { ApplicationState } from "store";
import * as DistributorDepotsStore from "../../store/DistributorDepotsReducer";
import "react-dropdown/style.css";
import Form from "../Form";
import Button from "../Button";
import TextInput from "../TextInput";
import regions from "../../regions.json";
import Select from "../Select";
import { AddIcon, BackIcon, EditIcon } from "../../svg";
import * as endpoints from "../constants/endpoints";
import styled from "styled-components";
import Cookies from "universal-cookie";
import * as actions from "components/Utils/actions";
import { USER_ROLES } from "components/Utils/actions";
import i18next from "i18next";

const cookies = new Cookies();

type DistributorDepotsProps = DistributorDepotsStore.DistributorDepotsState &
  typeof DistributorDepotsStore.actionCreators &
  RouteComponentProps<{
    distributorId: any;
    tenantId: any;
    depotId: any;
    distributorName: any;
  }>;

class ManageDistributorDepotForm extends React.PureComponent<DistributorDepotsProps> {
  constructor(props) {
    super(props);
  }
  public state = {
    depotName: "",
    depotCode: "",
    hermesDepotCode: "",
    tenantId: "",
    distributorId: "",
    distributorName: "",
    selectedRegion: "North-East",
    softwareProviderCompanyName: "",
    softwareProviderPhone: "",
    softwareProviderEmail: "",
    softwareProviderContactPerson: "",
    exportFolder: "",
    hermesDepots: [],
    officialDepotName: "",
    contactPersonName: "",
    contactPersonPhone: "",
    deliveryAddress: "",
    sapSoldToId: "",
    sapShipToId: "",
  };

  componentDidMount() {
    const selectedDistributorDepotId = this.props.match.params.depotId;
    this.props.attemptEditDistributorDepot(selectedDistributorDepotId);
    actions.getUserRole() !== USER_ROLES.CUSTOMER_SERVICE &&
      this.gerHermesDepots();
  }

  componentWillReceiveProps(nextProps) {
    var isEdit = this.props.match.params.depotId;
    if (isEdit) {
      const { selectedDistributorDepot } = nextProps;
      if (selectedDistributorDepot) {
        this.setState({
          depotName: selectedDistributorDepot["depotName"],
          depotCode: selectedDistributorDepot["depotCode"],
          hermesDepotCode: selectedDistributorDepot["hermesDepotId"],
          tenantId: selectedDistributorDepot["tenantId"],
          distributorId: selectedDistributorDepot["distributorId"],
          distributorName: selectedDistributorDepot["distributorName"],
          selectedRegion: selectedDistributorDepot["region"],
          softwareProviderCompanyName:
            selectedDistributorDepot["softwareProviderCompanyName"],
          softwareProviderPhone:
            selectedDistributorDepot["softwareProviderPhone"],
          softwareProviderEmail:
            selectedDistributorDepot["softwareProviderEmail"],
          softwareProviderContactPerson:
            selectedDistributorDepot["softwareProviderContactPerson"],
          exportFolder: selectedDistributorDepot["exportFolder"],
          officialDepotName: selectedDistributorDepot["officialDepotName"],
          contactPersonName: selectedDistributorDepot["contactPersonName"],
          contactPersonPhone: selectedDistributorDepot["contactPersonPhone"],
          deliveryAddress: selectedDistributorDepot["deliveryAddress"],
          sapSoldToId: selectedDistributorDepot["sapSoldToId"],
          sapShipToId: selectedDistributorDepot["sapShipToId"],
        });
      }
    } else {
      this.setState({
        tenantId: this.props.match.params.depotId,
        distributorId: this.props.match.params.distributorId,
      });
    }
  }

  gerHermesDepots() {
    const url = `${endpoints.MANAGE_HERMES_DEPOTS_ENDPOINT}`;
    fetch(url, {
      method: "GET",
      headers: {
        Accept: "*/*",
        "Content-Type": "application/json-patch+json",
        Authorization: `Bearer ${cookies.get("rawtoken")}`,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        this.setState({ hermesDepots: res });
      });
  }

  public handleSubmit = (event) => {
    var isEdit = this.props.match.params.depotId;
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    const editedDistributorDepot = {
      id: this.props.selectedDistributorDepot.id,
      depotName: this.state.depotName,
      depotCode: this.state.depotCode,
      hermesDepotId: parseInt(this.state.hermesDepotCode),
      tenantId: this.state.tenantId,
      distributorId: this.state.distributorId,
      region: this.state.selectedRegion,
      softwareProviderCompanyName: this.state["softwareProviderCompanyName"],
      softwareProviderPhone: this.state["softwareProviderPhone"],
      softwareProviderEmail: this.state["softwareProviderEmail"],
      softwareProviderContactPerson:
        this.state["softwareProviderContactPerson"],
      exportFolder: this.state["exportFolder"],
      officialDepotName: this.state["officialDepotName"],
      contactPersonName: this.state["contactPersonName"],
      contactPersonPhone: this.state["contactPersonPhone"],
      deliveryAddress: this.state["deliveryAddress"],
      sapSoldToId: this.state["sapSoldToId"],
      sapShipToId: this.state["sapShipToId"],
    };
    if (isEdit) {
      editedDistributorDepot["isActive"] = true;
      this.props.editDistributorDepot(editedDistributorDepot);
    } else {
      this.props.addDistributorDepot(editedDistributorDepot);
    }
    this.setState({
      validated: true,
    });
  };

  public onChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  navigateBack = (event) => {
    this.props.history.push(
      "/edit-distributor-depots/" + this.props.match.params.distributorId,
    );
  };

  public render() {
    const { isLoading } = this.props;
    const userRole = actions.getUserRole();
    const isUserCustomerService = userRole === USER_ROLES.CUSTOMER_SERVICE;

    var isEdit = this.props.match.params.depotId,
      distributorName = this.props.match.params.distributorName,
      regs = regions
        ? regions.map((region, id) => {
            return {
              value: region.name,
              label: region.name,
            };
          })
        : [];
    const hermesDepots = this.state.hermesDepots?.map((depo) => {
      return {
        value: depo.id,
        label: depo.name,
      };
    });

    const RelativeContainer = styled.div`
      position: relative;

      div:first-of-type {
        z-index: 1;
        position: relative;
      }
      div:last-of-type {
        position: absolute;
        bottom: -30px;
        left: 0;
        // margin: 0;
        width: 100%;
        z-index: 0;
      }

      .visible-field {
      }
      .hidden-field {
        // position: absolute;
        // bottom: 0;
        // left: 0;
        // margin: 0;
        visibility: hidden;
      }
    `;

    return (
      <Form
        title={`${isEdit ? i18next.t("editing_depot_for_distributor") : i18next.t("creating_depot_for_distributor")} ${distributorName}`}
        hasBox
        buttonSize="large"
        buttonIcon={isEdit ? AddIcon : EditIcon}
        buttonText={isEdit ? i18next.t("save") : i18next.t("create")}
        buttonJustify="space-between"
        additionalButton={
          <Button
            icon={BackIcon}
            size="large"
            variant="outlined"
            onClick={this.navigateBack}
          >
            {i18next.t("back")}
          </Button>
        }
        onSubmit={this.handleSubmit}
        {...{ isLoading }}
      >
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div style={{ width: "48%" }}>
            {!isUserCustomerService && (
              <>
                <TextInput
                  label={i18next.t("name")}
                  name="depotName"
                  id="depotName"
                  type="text"
                  required
                  value={this.state.depotName}
                  onChange={this.onChange}
                />
                <TextInput
                  label={i18next.t("depot_code")}
                  name="depotCode"
                  id="depotCode"
                  type="text"
                  required
                  value={this.state.depotCode}
                  onChange={this.onChange}
                />
              </>
            )}
            <TextInput
              label={i18next.t(" official_depot_name")}
              name="officialDepotName"
              id="officialDepotName"
              type="text"
              value={this.state.officialDepotName}
              onChange={this.onChange}
            />
            <TextInput
              label={i18next.t("contact_person_name")}
              name="contactPersonName"
              id="contactPersonName"
              type="text"
              value={this.state.contactPersonName}
              onChange={this.onChange}
            />
            <TextInput
              label={i18next.t("contact_person_phone")}
              name="contactPersonPhone"
              id="contactPersonPhone"
              type="text"
              value={this.state.contactPersonPhone}
              onChange={this.onChange}
            />
            <TextInput
              label={i18next.t("delivery_address")}
              name="deliveryAddress"
              id="deliveryAddress"
              type="text"
              value={this.state.deliveryAddress}
              onChange={this.onChange}
            />

            <TextInput
              label={i18next.t("sap_ship_to_id")}
              name="sapShipToId"
              id="sapShipToId"
              type="text"
              value={this.state.sapShipToId}
              onChange={this.onChange}
            />
            <TextInput
              label={i18next.t("sap_sold_to_id")}
              name="sapSoldToId"
              id="sapSoldToId"
              type="text"
              value={this.state.sapSoldToId}
              onChange={this.onChange}
            />
          </div>
          {!isUserCustomerService && (
            <div style={{ width: "48%" }}>
              <RelativeContainer>
                <Select
                  label={i18next.t("hermes_depot")}
                  name="hermesDepotCode"
                  value={this.state.hermesDepotCode}
                  onChange={this.onChange}
                  options={hermesDepots}
                  required={true}
                  className="visible-field"
                />
                <TextInput
                  label=""
                  name="hermesDepotCode"
                  id="hermesDepotCode"
                  type="text"
                  required
                  value={this.state.hermesDepotCode}
                  onChange={this.onChange}
                  className="hidden-field"
                />
              </RelativeContainer>
              <TextInput
                label={i18next.t("software_provider_company_name")}
                name="softwareProviderCompanyName"
                id="softwareProviderCompanyName"
                type="text"
                required
                value={this.state.softwareProviderCompanyName}
                onChange={this.onChange}
              />
              <TextInput
                label={i18next.t("software_provider_contact_person")}
                name="softwareProviderContactPerson"
                id="softwareProviderContactPerson"
                type="text"
                required
                value={this.state.softwareProviderContactPerson}
                onChange={this.onChange}
              />
              <TextInput
                label={i18next.t("software_provider_email")}
                name="softwareProviderEmail"
                id="softwareProviderEmail"
                type="text"
                required
                value={this.state.softwareProviderEmail}
                onChange={this.onChange}
              />
              <TextInput
                label={i18next.t("software_provider_phone")}
                name="softwareProviderPhone"
                id="softwareProviderPhone"
                type="text"
                required
                value={this.state.softwareProviderPhone}
                onChange={this.onChange}
              />
              <Select
                label={i18next.t("region")}
                name="selectedRegion"
                value={this.state.selectedRegion}
                defaultValue={"-1"}
                onChange={this.onChange}
                options={regs}
              />
              <TextInput
                label={i18next.t("export_folder")}
                name="exportFolder"
                id="exportFolder"
                type="text"
                value={this.state.exportFolder}
                onChange={this.onChange}
              />
            </div>
          )}
        </div>
      </Form>
    );
  }
}

export default connect(
  (state: ApplicationState) => state.distributorDepots,
  DistributorDepotsStore.actionCreators,
)(ManageDistributorDepotForm);
