import React, { useState } from "react";

import { ToolOutlined } from "@ant-design/icons";
import { Flex, Image } from "antd";
import dayjs from "dayjs";
import i18next from "i18next";

import {
  UpsertProductRequestBody,
  useGetAllProducts,
} from "api/reactQuery/queryComponents";
import { BSCoreServicesDtoMarketplaceProductDto } from "api/reactQuery/querySchemas";
import Button from "components/Button";
import FilterableTableContainer from "components/Helpers/FilterableTableContainer";
import CreateEditProductModal from "components/Products/Modals/CreateEditProductModal";
import OnInvoiceConfigModal from "components/Products/Modals/OnInvoiceConfigModal";
import WholesalerSettingModal from "components/Products/Modals/WholesalerSettingModal";
import * as roles from "components/Utils/actions";
import { USER_ROLES } from "components/Utils/actions";
import { dateFormat } from "components/Utils/dayjsUtils";
import {
  MarketplacePalletsGroupTypes,
  MarketplacePalletsTypes,
} from "components/Utils/enums";
import { AddIcon, EditIcon } from "svg";

const prepareProductForEdit = (
  product: BSCoreServicesDtoMarketplaceProductDto
): UpsertProductRequestBody & { imgUrl?: string } => {
  return {
    "Availability.CurrentAvailableUnits":
      product?.availability?.currentAvailableUnits,
    "Availability.ExpiryDate": product?.availability?.expiryDate
      ? (dayjs(product?.availability?.expiryDate) as unknown as string)
      : undefined,
    "Availability.ImportComment": product?.availability?.importComment,
    "Availability.NextAvailableUnits":
      product?.availability?.nextAvailableUnits,
    "Availability.NextAvailableUnitsDate": product?.availability
      ?.nextAvailableUnitsDate
      ? (dayjs(
          product?.availability?.nextAvailableUnitsDate
        ) as unknown as string)
      : undefined,
    Barcode: product?.barcode,
    CustomUnitsForOrder: product?.customUnitsForOrder,
    GroupType: product?.groupType,
    Id: product?.id,
    InvoicePrice: product?.invoicePrice,
    IsActive: product?.isActive,
    IsMultiPack: product?.isMultiPack,
    Name: product?.name,
    PackageId: product?.packaging.id,
    PalletId: product?.pallet.id,
    Prices: product?.prices,
    SAPNumber: product?.sapNumber,
    SortIndex: product?.sortIndex,
    UnitsInPallet: product?.unitsInPallet,
    UnitsInRow: product?.unitsInRow,
    VolumeInHL: product?.volumeInHL,
    Weight: product?.weight,
    imgUrl: product?.imgUrl,
  };
};

const Products: React.FC = () => {
  const { data: products, refetch, isLoading } = useGetAllProducts({});
  const [wholesalerModalOpen, setWholesalerModalOpen] = useState(false);
  const [onInvoiceModalOpen, setOnInvoiceModalOpen] = useState(false);
  const [productModalOpen, setProductModalOpen] = useState(false);
  const [productForEdit, setProductForEdit] = useState<
    UpsertProductRequestBody & { imgUrl?: string }
  >();
  const role = roles.getUserRole();
  const resetProductForEdit = (modalOpen: boolean) =>
    productForEdit && modalOpen && setProductForEdit(undefined);
  const toggleProductModalOpen = () => {
    resetProductForEdit(productModalOpen);
    setProductModalOpen(!productModalOpen);
  };
  const toggleWholesalerModalOpen = () => {
    resetProductForEdit(wholesalerModalOpen);
    setWholesalerModalOpen(!wholesalerModalOpen);
  };
  const toggleOnInvoiceModalOpen = () => {
    resetProductForEdit(onInvoiceModalOpen);
    setOnInvoiceModalOpen(!onInvoiceModalOpen);
  };

  const tableProps = {
    data: products,
    fields: [
      {
        displayName: i18next.t("image"),
        name: "imgUrl",
        render: ({ value }) =>
          value && (
            <Flex style={{ width: "100%" }} justify="center">
              <Image
                style={{ height: "50px", width: "auto" }}
                src={value}
                alt="Product img"
              />
            </Flex>
          ),
        tdClassName: "size-s",
      },
      {
        displayName: i18next.t("name"),
        inputFilterable: true,
        name: "name",
        sortable: true,
        tdClassName: "size-s",
      },
      {
        displayName: i18next.t("sap_number"),
        inputFilterable: true,
        name: "sapNumber",
        sortable: true,
      },
      {
        displayName: i18next.t("barcode"),
        inputFilterable: true,
        name: "barcode",
        sortable: true,
        tdClassName: "size-s",
      },
      {
        displayName: i18next.t("units_in_pallet"),
        inputFilterable: true,
        name: "unitsInPallet",
        sortable: true,
      },
      {
        displayName: i18next.t("volume_in_hl"),
        inputFilterable: true,
        name: "volumeInHL",
        sortable: true,
      },
      {
        displayName: i18next.t("weight"),
        inputFilterable: true,
        name: "weight",
        sortable: true,
      },
      {
        displayName: i18next.t("sort_index"),
        inputFilterable: true,
        name: "sortIndex",
        sortable: true,
      },
      {
        displayName: i18next.t("pallet"),
        inputFilterable: true,
        name: "pallet.type",
        render: ({ value }) => MarketplacePalletsTypes[value],
        sortable: true,
      },
      {
        displayName: i18next.t("packaging"),
        inputFilterable: true,
        name: "packaging.name",
        sortable: true,
      },
      {
        displayName: i18next.t("expiry_date"),
        inputFilterable: true,
        name: "availability.expiryDate",
        render: ({ value }) =>
          value ? dayjs(value).format(dateFormat) : undefined,
        sortable: true,
      },
      {
        displayName: i18next.t("next_available_units_date"),
        inputFilterable: true,
        name: "availability.nextAvailableUnitsDate",
        render: ({ value }) =>
          value ? dayjs(value).format(dateFormat) : undefined,
        sortable: true,
      },
      {
        displayName: i18next.t("import_comment"),
        inputFilterable: true,
        name: "availability.importComment",
        sortable: true,
        tdClassName: "size-m",
      },
      {
        displayName: i18next.t("next_available_units"),
        inputFilterable: true,
        name: "availability.nextAvailableUnits",
        sortable: true,
      },
      {
        displayName: i18next.t("current_available_units"),
        inputFilterable: true,
        name: "availability.currentAvailableUnits",
        sortable: true,
      },
      {
        displayName: i18next.t("group_type"),
        inputFilterable: true,
        name: "groupType",
        render: ({ value }) => MarketplacePalletsGroupTypes[value],
        sortable: true,
      },
      {
        displayName: i18next.t("multipack"),
        inputFilterable: true,
        name: "isMultiPack",
        render: ({ value }) => (value ? i18next.t("yes") : i18next.t("no")),
        sortable: true,
      },
      {
        displayName: i18next.t("status"),
        inputFilterable: true,
        name: "isActive",
        render: ({ value }) =>
          value ? i18next.t("active") : i18next.t("inactive"),
        sortable: true,
      },
      {
        displayName: i18next.t("actions"),
        name: "edit",
        render: (props) => (
          <Flex>
            {role !== USER_ROLES.TENANT_SUPERVISOR && (
              <Button
                icon={EditIcon}
                title={i18next.t("edit_product")}
                variant="outlined"
                onClick={() => {
                  setProductForEdit(prepareProductForEdit(props.record));
                  toggleProductModalOpen();
                }}
              />
            )}
            {role !== USER_ROLES.TENANT_SUPERVISOR && (
              <Button
                icon={ToolOutlined}
                variant="outlined"
                title={i18next.t("wholesaler")}
                onClick={() => {
                  setProductForEdit(prepareProductForEdit(props.record));
                  toggleWholesalerModalOpen();
                }}
              />
            )}
            {props.record.isInvoiceProduct && (
              <Button
                variant="outlined"
                title={i18next.t("oninvoice_configuration")}
                onClick={() => {
                  setProductForEdit(prepareProductForEdit(props.record));
                  toggleOnInvoiceModalOpen();
                }}
              >
                %
              </Button>
            )}
          </Flex>
        ),
      },
    ],
    initialSort: "sortIndex",
    tableName: i18next.t("products"),
  };

  return (
    <div style={{ paddingTop: 20 }}>
      <CreateEditProductModal
        isOpen={productModalOpen}
        toggleModalOpen={toggleProductModalOpen}
        onAfterSuccess={refetch}
        product={productForEdit}
      />
      <WholesalerSettingModal
        isOpen={wholesalerModalOpen}
        toggleModalOpen={toggleWholesalerModalOpen}
        productId={productForEdit?.Id}
        isMultiPack={productForEdit?.IsMultiPack}
      />
      <OnInvoiceConfigModal
        isOpen={onInvoiceModalOpen}
        toggleModalOpen={toggleOnInvoiceModalOpen}
        productId={productForEdit?.Id}
      />
      <FilterableTableContainer
        {...{ isLoading: isLoading }}
        hasActions
        tableProps={tableProps}
        buttons={
          role !== USER_ROLES.TENANT_SUPERVISOR && (
            <Button
              size="large"
              icon={AddIcon}
              onClick={toggleProductModalOpen}
            >
              {i18next.t("create_product")}
            </Button>
          )
        }
      />
    </div>
  );
};
export default Products;
