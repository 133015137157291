import React, { useEffect, useState } from "react";

import { WarningOutlined } from "@ant-design/icons";
import { UseMutateFunction } from "@tanstack/react-query";
import { ColDef, GridOptions } from "ag-grid-community";
import { Flex, TabsProps, Typography } from "antd";
import i18next from "i18next";

import { CalculateOrderVariables } from "api/reactQuery/queryComponents";
import {
  BSCoreServicesDtoMarketplaceOrderDto,
  BSCoreServicesDtoMarketplaceOrderProductDto,
  BSDALMarketplaceEnumsOrderStatus,
} from "api/reactQuery/querySchemas";
import {
  ProductLengthBadge,
  StyledAgGrid,
  StyledTabs,
} from "components/MpOrder/CreateOrder/OrderProductsTab/styled";
import useColDefs from "components/MpOrder/CreateOrder/OrderProductsTab/useColDefs";
import { MarketplaceOrderStatuses } from "components/Utils/enums";
import { getGridDefaultProps } from "constants/agGridConstants";

const defaultColumnDefs: ColDef = {
  autoHeaderHeight: true,
  flex: 1,
  maxWidth: 500,
  minWidth: 100,
  resizable: true,
  sortable: false,
  tooltipValueGetter(params) {
    return params.valueFormatted ? params.valueFormatted : params.value;
  },
  wrapHeaderText: true,
};

type Props = {
  orderData?: BSCoreServicesDtoMarketplaceOrderDto;
  enableEditOrderedQuantities: boolean;
  showAllProductsAvailability: boolean;
  showOnInvoiceProductsAvailability: boolean;
  showMultipackProductsAvailability: boolean;
  calculateOrder: UseMutateFunction<
    BSCoreServicesDtoMarketplaceOrderDto,
    { status: "unknown"; payload: string },
    CalculateOrderVariables,
    unknown
  >;
};
enum PRODUCT_TABS {
  ALL = "allProducts",
  MULTIPACK = "multiPackProducts",
  ONINVOICE = "onInvoiceProducts",
}

const OrderProductsTabs: React.FC<Props> = ({
  orderData,
  calculateOrder,
  enableEditOrderedQuantities,
  showAllProductsAvailability,
  showOnInvoiceProductsAvailability,
  showMultipackProductsAvailability,
}) => {
  const [activeTab, setActiveTab] = useState<PRODUCT_TABS>(PRODUCT_TABS.ALL);

  const isNewOrder =
    MarketplaceOrderStatuses[orderData?.status] ===
    BSDALMarketplaceEnumsOrderStatus.New;

  const getFilteredProducts = (
    data: BSCoreServicesDtoMarketplaceOrderDto,
    isInvoiceProduct: boolean
  ) =>
    data?.products?.filter(
      (product) =>
        product.isInvoiceProduct === isInvoiceProduct && !product.isMultiPack
    );
  const allProducts = getFilteredProducts(orderData, false);
  const onInvoiceProducts = getFilteredProducts(orderData, true);
  const multiPackProducts = orderData?.products?.filter(
    (product) => product.isMultiPack
  );
  useEffect(() => {
    if (allProducts.length) setActiveTab(PRODUCT_TABS.ALL);
    else if (onInvoiceProducts.length) setActiveTab(PRODUCT_TABS.ONINVOICE);
    else if (multiPackProducts.length) setActiveTab(PRODUCT_TABS.MULTIPACK);
  }, [allProducts.length, multiPackProducts.length, onInvoiceProducts.length]);

  const { columnDefs: allProductsColumnDefs } = useColDefs({
    enableEditOrderedQuantities,
    showCurrentAvailableUnits: showAllProductsAvailability,
  });
  const { columnDefs: onInvoiceColumnDefs } = useColDefs({
    enableEditOrderedQuantities,
    showCurrentAvailableUnits: showOnInvoiceProductsAvailability,
    showMultiPackQuantityLimit: false,
    showOnInvoiceQuantityLimit: true,
  });
  const { columnDefs: multiPackColumnDefs } = useColDefs({
    enableEditOrderedQuantities,
    showCurrentAvailableUnits: showMultipackProductsAvailability,
    showMultiPackQuantityLimit: true,
    showOnInvoiceQuantityLimit: false,
  });

  const columnDefsForTabs: {
    [key in PRODUCT_TABS]: ColDef<BSCoreServicesDtoMarketplaceOrderProductDto>[];
  } = {
    [PRODUCT_TABS.ALL]: allProductsColumnDefs,
    [PRODUCT_TABS.MULTIPACK]: multiPackColumnDefs,
    [PRODUCT_TABS.ONINVOICE]: onInvoiceColumnDefs,
  };
  const productsForTabs: {
    [key in PRODUCT_TABS]: BSCoreServicesDtoMarketplaceOrderProductDto[];
  } = {
    [PRODUCT_TABS.ALL]: allProducts,
    [PRODUCT_TABS.MULTIPACK]: multiPackProducts,
    [PRODUCT_TABS.ONINVOICE]: onInvoiceProducts,
  };

  const onCellEditingStopped: GridOptions<BSCoreServicesDtoMarketplaceOrderProductDto>["onCellEditingStopped"] =
    (event) => {
      const tempData = { ...orderData };
      const product = tempData?.products?.find(
        (product) =>
          product.productId === event.data.productId &&
          product.isInvoiceProduct === event.data.isInvoiceProduct
      );

      if (product) {
        if (event.colDef.field === "orderedQuantitiesInUnits") {
          product.orderedQuantitiesInUnits =
            event.newValue === null ? 0 : event.newValue;
        }

        if (event.colDef.field === "orderedQuantitiesInPallets") {
          product.orderedQuantitiesInPallets =
            event.newValue === null ? 0 : event.newValue;
        }
      }
      event.valueChanged && calculateOrder({ body: tempData });
    };
  const getRowStyle: GridOptions<BSCoreServicesDtoMarketplaceOrderProductDto>["getRowStyle"] =
    (params) => {
      if (
        (params.data.currentAvailableUnits !== null &&
          params.data.orderedUnits > params.data.currentAvailableUnits &&
          !isNewOrder) ||
        (activeTab === PRODUCT_TABS.ONINVOICE &&
          !isNewOrder &&
          params.data.orderedUnits > params.data.onInvoiceQuantityLimit) ||
        (activeTab === PRODUCT_TABS.MULTIPACK &&
          !isNewOrder &&
          params.data.orderedUnits > params.data.multiPackQuantityLimit)
      )
        return {
          background: "#FED9D9",
        };
    };

  const renderGrid = (height: number) => (
    <div style={{ height }}>
      <StyledAgGrid
        singleClickEdit
        rowHeight={64}
        getRowStyle={getRowStyle}
        getRowId={({ data }) => String(data.productId)}
        rowData={productsForTabs[activeTab]}
        columnDefs={columnDefsForTabs[activeTab]}
        onCellEditingStopped={onCellEditingStopped}
        defaultColDef={defaultColumnDefs}
        {...getGridDefaultProps({ includeDefaultColDefs: false })}
      />
    </div>
  );

  const getProductBadges = (tab: PRODUCT_TABS) => {
    const products = productsForTabs[tab];

    const warningLabel = () => {
      if (
        (!isNewOrder &&
          products?.some(
            (product) =>
              product.currentAvailableUnits !== null &&
              product.orderedUnits > product.currentAvailableUnits
          )) ||
        (tab === PRODUCT_TABS.ONINVOICE &&
          !isNewOrder &&
          products?.some(
            (product) => product.orderedUnits > product.onInvoiceQuantityLimit
          )) ||
        (tab === PRODUCT_TABS.MULTIPACK &&
          !isNewOrder &&
          products?.some(
            (product) => product.orderedUnits > product.multiPackQuantityLimit
          ))
      ) {
        return (
          <WarningOutlined
            style={{ color: "red", fontSize: 20, verticalAlign: "middle" }}
          />
        );
      }
    };

    return (
      <>
        <ProductLengthBadge>{products?.length}</ProductLengthBadge>
        {warningLabel()}
      </>
    );
  };

  const items: TabsProps["items"] = [
    !!onInvoiceProducts?.length && {
      children: renderGrid(400),
      key: PRODUCT_TABS.ONINVOICE,
      label: (
        <>
          {i18next.t("on_invoice_products")}
          {getProductBadges(PRODUCT_TABS.ONINVOICE)}
        </>
      ),
    },
    !!multiPackProducts?.length && {
      children: renderGrid(400),
      key: PRODUCT_TABS.MULTIPACK,
      label: (
        <>
          {i18next.t("multipack_products")}
          {getProductBadges(PRODUCT_TABS.MULTIPACK)}
        </>
      ),
    },
    !!allProducts.length && {
      children: renderGrid(500),
      key: PRODUCT_TABS.ALL,
      label: (
        <>
          {i18next.t("all_products")}
          {getProductBadges(PRODUCT_TABS.ALL)}
        </>
      ),
    },
  ];

  return !allProducts.length &&
    !multiPackProducts.length &&
    !onInvoiceProducts.length ? (
    <Flex justify="center">
      <Typography.Title style={{ color: "#6d6d6d", marginTop: 50 }}>
        {i18next.t("no_products")}
      </Typography.Title>
    </Flex>
  ) : (
    <StyledTabs
      defaultActiveKey={activeTab}
      activeKey={activeTab}
      onChange={(key: PRODUCT_TABS) => setActiveTab(key)}
      items={items}
    />
  );
};
export default OrderProductsTabs;
