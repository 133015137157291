import { checkHttpStatus, parseJSON, DEFAULT_HEADERS } from '../Utils/actions';
import Cookies from 'universal-cookie';
import * as endpoints from '../constants/endpoints'

const cookies = new Cookies();

export function getTenantsAction(callback, error) {
    return fetch(endpoints.MANAGE_TENANTS_ENDPOINT, {
        method: 'get',
        headers: {'Accept': '*/*','Content-Type': 'application/json-patch+json','Authorization': `Bearer ${cookies.get('rawtoken')}`}
    })
        .then(checkHttpStatus)
        .then(parseJSON)
        .then(response => {
            try {
                callback(response);
            } catch (e) {
                error(e);
            }
        })
        .catch(e => {
            error(e);
        })
}

export function editTenant(editedTenant, callback, error) {
    var url = endpoints.MANAGE_TENANTS_ENDPOINT;
    url += editedTenant.id;

    return fetch(url, {
        method: 'put',
        headers: {'Accept': '*/*','Content-Type': 'application/json-patch+json','Authorization': `Bearer ${cookies.get('rawtoken')}`},
        body: JSON.stringify(editedTenant)
    })
        .then(checkHttpStatus)
        .then(() => {
            try {
                callback();
            } catch (e) {
                error(e);
            }
        })
        .catch(e => {
            error(e);
        })
}

export function addTenant(newTenant, callback, error) {
    var url = endpoints.MANAGE_TENANTS_ENDPOINT;

    return fetch(url, {
        method: 'post',
        headers: {'Accept': '*/*','Content-Type': 'application/json-patch+json','Authorization': `Bearer ${cookies.get('rawtoken')}`},
        body: JSON.stringify(newTenant)
    })
        .then(checkHttpStatus)
        .then(() => {
            try {
                callback();
            } catch (e) {
                error(e);
            }
        })
        .catch(e => {
            error(e);
        })
} 

export function deleteTenant(tenantId, callback, error) {
    var url = endpoints.MANAGE_TENANTS_ENDPOINT;
    url += tenantId;

    return fetch(url, {
        method: 'delete',
        headers: {'Accept': '*/*','Content-Type': 'application/json-patch+json','Authorization': `Bearer ${cookies.get('rawtoken')}`}
    })
        .then(checkHttpStatus)
        .then(() => {
            try {
                callback();
            } catch (e) {
                error(e);
            }
        })
        .catch(e => {
            error(e);
        })
} 

export function attemptEditTenant(selectedTenantId, callback, error) {
    var url = endpoints.MANAGE_TENANTS_ENDPOINT;
    url += selectedTenantId;

    return fetch(url, {
        method: 'get',
        headers: {'Accept': '*/*','Content-Type': 'application/json-patch+json','Authorization': `Bearer ${cookies.get('rawtoken')}`}
    })
        .then(checkHttpStatus)
        .then(parseJSON)
        .then(response => {
            try {
                callback(response);
            } catch (e) {
                error();
            }
        })
        .catch(e => {
            error();
        })
} 