import React from 'react'
import { connect } from 'react-redux';

import * as endpoints from './constants/endpoints'
import Table from './Table';
import _ from 'lodash';

import Cookies from 'universal-cookie';
const cookies = new Cookies();

interface T {
    label: string
}

interface Props {
    title?: any,
    tableSettings?: any,
    selectedRows?: any,
    emitHermesId?: any,
    selectedProductId?: any
}

class TableWrapperHermesSku extends React.Component<Props>{
    constructor(props) {
        super(props);

        this.getHermesData = this.getHermesData.bind(this);
        this.onSorterClick = this.onSorterClick.bind(this);
        this.paginationUpdated = this.paginationUpdated.bind(this);
        this.emitSelectedIds = this.emitSelectedIds.bind(this);
        this.emitFilterValue = this.emitFilterValue.bind(this);
        this.onFilterHermes = _.debounce(this.getHermesData , 2000);
        this.emitExportData = this.emitExportData.bind(this)
    }

    state = {
        dataHermes: [],
        hermesCollectionIds: [],
        hermesCollection: [],
        hermesTotalSize: null,
        hermesItemsPerPage: 10,
        hermesCurrentPage: 1,
        isHermesLoading: true,
        hermesOrderBy: 'name', // rank
        hermesOrderDirection: 'asc', // desc
        selectedHermesId: '',
        hermesFilters: {
            name: '',
            packaging: '',
            quantity: '',
            volume: '',
            searchValue: ''
        },
        hasChanged: false,
        shouldReRender: true,
        shouldRender: true,
        showTable: false
    }

    getHermesData = () => {
        this.setState({isHermesLoading: true})

        const url = `${endpoints.SKU_MAPPINGS_ENDPOINT}/hermesskus`

        fetch(url, {
            method: 'post',
            headers: {
                'Accept': '*/*',
                'Content-Type': 'application/json-patch+json',
                'Authorization': `Bearer ${cookies.get('rawtoken')}`
            },
            body: JSON.stringify({
                page:this.state.hermesCurrentPage - 1,
                pageSize:this.state.hermesItemsPerPage,
                orderBy: this.state.hermesOrderBy,
                orderDirection: this.state.hermesOrderDirection,// asc desc
                selectedId: this.props.selectedProductId,
                filters: {
                    name: this.state.hermesFilters.name,
                    packaging: this.state.hermesFilters.packaging,
                    quantity: this.state.hermesFilters.quantity,
                    volume: this.state.hermesFilters.volume,
                    searchValue: this.state.hermesFilters.searchValue
                },
            })
        }).then(res => res.json())
          .then(res => {
              _.set(this.state, 'hermesTotalSize', res.dataTotalSize);
              _.set(this.state, 'dataHermes', res.data);
              _.set(this.state, 'isHermesLoading', false);

              this.setState({
                  isHermesLoading: false,
                  showTable: true
              })
          });
    }

    onSorterClick(label, direction) {
        this.setState({
            hermesOrderBy: label,
            hermesOrderDirection: direction
        }, () => this.getHermesData())
    }

    paginationUpdated(itemsPerTable, currentPage) {
        this.setState({
            hermesItemsPerPage: itemsPerTable,
            hermesCurrentPage: currentPage,
        }, () => this.getHermesData())
    }

    emitSelectedIds(array) {
        if ( array.length === 0 ) {
            _.set(this.state, 'selectedHermesId', '');
            _.set(this.state, 'hermesCollectionIds', []);
        } else {
            _.set(this.state, 'selectedHermesId', array[0]);
            _.set(this.state, 'hermesCollectionIds', [array[0]]);
        }


        this.props.emitHermesId(this.state.selectedHermesId)
    }

    emitFilterValue(value, key) {
        _.set(this.state, `hermesFilters.${key}`, value);
        _.set(this.state, 'hermesCurrentPage', 1);

        this.onFilterHermes()
    }

    onFilterPocMapping() {
        this.getHermesData()
    }

    onFilterHermes() {
        this.getHermesData()
    }

    emitExportData() {
        const url = `${endpoints.POC_MAPPINGS_ENDPOINT}/ExportUnmappedExcel`

        fetch(url, {
            method: 'POST',
            headers: {
                'Accept': '*/*',
                'Content-Type': 'application/json-patch+json',
                'Authorization': `Bearer ${cookies.get('rawtoken')}`
            },
            body: JSON.stringify({
                filters: {
                    name: this.state.hermesFilters.name,
                    packaging: this.state.hermesFilters.packaging,
                    quantity: this.state.hermesFilters.quantity,
                    volume: this.state.hermesFilters.volume,
                    searchValue: this.state.hermesFilters.searchValue
                }
            })
        })
        .then((res) => res.blob())
        .then((blob) => {
            var url = window.URL.createObjectURL(blob);
            var a = document.createElement("a");
            document.body.appendChild(a);
            a.href = url;
            a.download = `hermers_data.csv`;
            a.click();
            window.URL.revokeObjectURL(url);
        })
    }

    componentDidMount() {
        if ( this.props.selectedRows && this.props.selectedRows.length > 0 ) {
              const collectionIds = []

              this.props.selectedRows.map(item => {
                  collectionIds.push(item.hermesPocId)
              })

              this.setState({
                  hermesCollectionIds: [this.props.selectedProductId]
              }, () => {
                  this.getHermesData()
              })
        }
    }

    public render() {
        return (
            <React.Fragment>
                {this.state.showTable && (
                    <Table
                        type="hermes_sku"
                        title="Hermes SKU"
                        tableSettings={this.props.tableSettings}
                        data={this.state.dataHermes}
                        onSorterClick={this.onSorterClick}
                        allowMultipleSelection={false}
                        paginationUpdated={this.paginationUpdated}
                        totalItems={this.state.hermesTotalSize ? this.state.hermesTotalSize : 0}
                        currentPage={this.state.hermesCurrentPage}
                        itemsPerPage={this.state.hermesItemsPerPage}
                        isLoading={this.state.isHermesLoading}
                        emitSelectedIds={this.emitSelectedIds}
                        emitFilterValue={this.emitFilterValue}
                        filters={this.state.hermesFilters}
                        selectedRows={this.state.hermesCollectionIds}
                        currentSelectedFilter={this.state.hermesOrderBy}
                        currentSortingDirection={this.state.hermesOrderDirection}
                        shouldReRender={this.state.shouldReRender}
                        emitExportData={this.emitExportData}
                    />
                )}
            </React.Fragment>
        )
    }
}
export default connect (

)(TableWrapperHermesSku);
