import { Action, Reducer } from "redux";
import { AppThunkAction } from ".";
import { toast } from "react-toastify";
import * as actions from "../components/Actions/actions";
import Cookies from "universal-cookie";
import parseJwt from "../helpers/jwtParser";

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface AuthenticationState {
  isLoading: boolean;
  userCredentials: any;
  isAuthenticated: boolean;
  isValidEmail: boolean;
  responseMessage: string;
  currentUserInfo: any;
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.

interface AttemptAuthenticationAction {
  type: "ATTEMPT_AUTHENTICATION";
}

interface CompleteAuthenticationAction {
  type: "COMPLETE_AUTHENTICATION_ACTION";
  isAuthenticated: boolean;
  responseMessage: string;
}

interface CompleteChangePasswordAction {
  type: "COMPLETE_CHANGE_PASSWORD_ACTION";
  isValidEmail: boolean;
  responseMessage: string;
}

interface AuthenticationDenied {
  type: "AUTHENTICATION_DENIED";
  responseMessage: string;
}

interface CompleteAuthenticationGetInfoAction {
  type: "COMPLETE_AUTHENTICATION_GET_INFO_ACTION";
  currentUserInfo: string;
}

interface RemoveAuthenticationAction {
  type: "REMOVE_AUTHENTICATION_ACTION";
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
type KnownAction =
  | AttemptAuthenticationAction
  | CompleteAuthenticationAction
  | AuthenticationDenied
  | CompleteChangePasswordAction
  | CompleteAuthenticationGetInfoAction
  | RemoveAuthenticationAction;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
  attemptAuthentication:
    (userCredentials: any, callBack): AppThunkAction<KnownAction> =>
    (dispatch, getState) => {
      // Only load data if it's something we don't already have (and are not already loading)
      const appState = getState();
      if (appState && !appState.authentication.isAuthenticated) {
        dispatch({ type: "ATTEMPT_AUTHENTICATION" });
        let callback = () => {
            const message = "You have successfully logged in";
            dispatch({
              type: "COMPLETE_AUTHENTICATION_ACTION",
              isAuthenticated: true,
              responseMessage: message,
            });
            callBack();
            toast.success(message || "Success");
          },
          error = (error) => {
            dispatch({
              type: "AUTHENTICATION_DENIED",
              responseMessage: error.response
                ? error.response.statusText
                : "Error",
            });
            toast.error(error.response ? error.response.statusText : "Error");
          };
        actions.loginUser(userCredentials, callback, error);
      }
    },
  attemptSendVerificationCode:
    (userCredentials: any): AppThunkAction<KnownAction> =>
    (dispatch, getState) => {
      // Only load data if it's something we don't already have (and are not already loading)
      const appState = getState();
      if (appState) {
        dispatch({ type: "ATTEMPT_AUTHENTICATION" });
        let callback = () => {
            const message = "A verification code has been sent to your e-mail.";
            dispatch({
              type: "COMPLETE_CHANGE_PASSWORD_ACTION",
              isValidEmail: true,
              responseMessage: message,
            });
            toast.success(message || "Success");
          },
          error = (error) => {
            dispatch({
              type: "AUTHENTICATION_DENIED",
              responseMessage: error.response
                ? error.response.statusText
                : "Error",
            });
            toast.error(error.response ? error.response.statusText : "Error");
          };
        actions.sendVerificationCode(userCredentials, callback, error);
      }
    },
  attemptChangePassword:
    (userCredentials: any): AppThunkAction<KnownAction> =>
    (dispatch, getState) => {
      // Only load data if it's something we don't already have (and are not already loading)
      const appState = getState();
      if (appState) {
        dispatch({ type: "ATTEMPT_AUTHENTICATION" });
        let callback = () => {
            const message = "You have successfully changed your password.";
            dispatch({
              type: "COMPLETE_CHANGE_PASSWORD_ACTION",
              isValidEmail: true,
              responseMessage: message,
            });
            toast.success(message || "Success");
          },
          error = (error) => {
            dispatch({
              type: "AUTHENTICATION_DENIED",
              responseMessage: error.response
                ? error.response.statusText
                : "Error",
            });
            toast.error(error.response ? error.response.statusText : "Error");
          };
        actions.changePassword(userCredentials, callback, error);
      }
    },
  changeAuthentication:
    (authenticated): AppThunkAction<KnownAction> =>
    (dispatch, getState) => {
      // Only load data if it's something we don't already have (and are not already loading)
      const appState = getState();
      if (appState) {
        dispatch({ type: "ATTEMPT_AUTHENTICATION" });
        let callback = () => {
            const message = "You have successfully logged out.";
            dispatch({
              type: "COMPLETE_AUTHENTICATION_ACTION",
              isAuthenticated: authenticated ? authenticated : false,
              responseMessage: message,
            });
          },
          error = (error) => {
            dispatch({
              type: "AUTHENTICATION_DENIED",
              responseMessage: error.response
                ? error.response.statusText
                : "Error",
            });
            toast.error(error.response ? error.response.statusText : "Error");
          };

        actions.changeAuthenticated(authenticated, callback, error);
      }
    },
  getCurrentLoggedUser: (): AppThunkAction<KnownAction> => (dispatch) => {
    const cookies = new Cookies();
    const jwt = parseJwt(cookies.get("rawtoken"));
    dispatch({
      type: "COMPLETE_AUTHENTICATION_GET_INFO_ACTION",
      currentUserInfo: jwt.UserName ? jwt.UserName : "no logged user",
    });
  },
  removeAuthentication: (): AppThunkAction<KnownAction> => (dispatch) => {
    dispatch({ type: "REMOVE_AUTHENTICATION_ACTION" });
  },
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const unloadedState: AuthenticationState = {
  isAuthenticated: false,
  isValidEmail: false,
  isLoading: false,
  userCredentials: {},
  responseMessage: "",
  currentUserInfo: "",
};

export const reducer: Reducer<AuthenticationState> = (
  state: AuthenticationState | undefined,
  incomingAction: Action,
): AuthenticationState => {
  if (state === undefined) {
    return unloadedState;
  }

  const action = incomingAction as KnownAction;
  switch (action.type) {
    case "ATTEMPT_AUTHENTICATION":
      return {
        ...state,
        isLoading: true,
        responseMessage: "",
      };
    case "COMPLETE_AUTHENTICATION_ACTION":
      // Only accept the incoming data if it matches the most recent request. This ensures we correctly
      // handle out-of-order responses.
      return {
        ...state,
        isLoading: false,
        isAuthenticated: action.isAuthenticated,
        responseMessage: action.responseMessage,
      };
      break;
    case "COMPLETE_CHANGE_PASSWORD_ACTION":
      // Only accept the incoming data if it matches the most recent request. This ensures we correctly
      // handle out-of-order responses.
      return {
        ...state,
        isLoading: false,
        isValidEmail: action.isValidEmail,
        responseMessage: action.responseMessage,
      };
      break;
    case "AUTHENTICATION_DENIED":
      return {
        ...state,
        responseMessage: action.responseMessage,
        isLoading: false,
      };
      break;
    case "COMPLETE_AUTHENTICATION_GET_INFO_ACTION":
      return {
        ...state,
        currentUserInfo: action.currentUserInfo,
        isLoading: false,
      };
      break;
    case "REMOVE_AUTHENTICATION_ACTION":
      return unloadedState;
  }

  return state;
};
