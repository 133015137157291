import * as React from "react";
import { connect } from "react-redux";
import {
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  CustomInput,
  Row,
  Col,
} from "reactstrap";
import * as endpoints from "../constants/endpoints";
import Cookies from "universal-cookie";
import { toast } from "react-toastify";
import Table from "../Table";
import _ from "lodash";
import styled from "styled-components";
import loader from "../../images/spinner.gif";
import i18next from "i18next";

export const excelFileTypes = {
  1: { name: "AopSellIn", label: i18next.t("aop_sell_in") },
  2: { name: "DbpGtoPerHl", label: i18next.t("dbp_gto_per_hl") },
  3: {
    name: "MonthlyTargetsPerDistributor",
    label: i18next.t("monthly_targets_per_distributor"),
  },
  4: {
    name: "MonthlyTargetsPerPoc",
    label: i18next.t("monthly_targets_per_poc"),
  },
  5: { name: "PmdHermes", label: i18next.t("pmdhermes") },
  6: {
    name: "SkuMappingHermesSap",
    label: i18next.t("sku_mapping_hermes-sap"),
  },
};
const cookies = new Cookies();

class DataLoad extends React.PureComponent<DataLoad> {
  state = {
    fileTypeToUpload: 1,
    fileToUpload: "",
    historyLogs: {
      page: 1,
      itemsPerPage: 20,
      orderBy: "", // name
      orderDirection: "", // asc
      totalSize: null,
      data: [],
      isLoading: false,
    },
    //enableInfiniteScroll: true,
    historyFilters: {},
    showOverlayLoader: false,
  };

  constructor(props) {
    super(props);
    this.paginationUpdated = this.paginationUpdated.bind(this);
  }

  componentDidMount() {
    this.GetLoadDataHistory();
  }

  GetLoadDataHistory = () => {
    const url = `${endpoints.DATA_LOAD_HISTORY}`;
    fetch(url, {
      method: "post",
      headers: {
        Accept: "*/*",
        "Content-Type": "application/json-patch+json",
        Authorization: `Bearer ${cookies.get("rawtoken")}`,
      },
      body: JSON.stringify({
        page: this.state.historyLogs.page - 1,
        pageSize: this.state.historyLogs.itemsPerPage,
        orderBy: this.state.historyLogs.orderBy,
        orderDirection: this.state.historyLogs.orderDirection,
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        const historyLogs = { ...this.state.historyLogs };

        res.data?.forEach((item) => {
          const dateArray = item.uploadDate.split("T");
          const date = new Date(dateArray[0]).toLocaleDateString("en-GB");
          const time = dateArray[1];
          item.uploadDate = `${date} at ${time.split(".")[0]}`;
        });

        historyLogs.totalSize = res.dataTotalSize;
        historyLogs.data = res.data;
        historyLogs.isLoading = false;
        this.setState({ historyLogs: historyLogs });
      });
    _.set(this.state, "dataErrors.itemsPerPage", 5);
  };

  getHistoryLogsSettings = () => {
    return {
      tableColumns: [
        {
          key: "fileType",
          title: i18next.t("file_type"),
          isFilterable: false,
          sortable: false,
        },
        {
          key: "uploadDate",
          title: i18next.t("upload_date"),
          isFilterable: false,
          sortable: false,
        },
        {
          key: "result",
          title: i18next.t("result"),
          isFilterable: false,
          sortable: false,
        },
        {
          key: "fileDates",
          title: i18next.t("file_dates"),
          isFilterable: false,
          sortable: false,
        },
        {
          key: "userName",
          title: i18next.t("user"),
          isFilterable: false,
          sortable: false,
        },
      ],
      tableCells: ["fileType", "uploadDate", "result", "fileDates", "userName"],
      searchSuccess: true,
      dateCreated: "0001-01-01T00:00:00",
      dateUpdated: "0001-01-01T00:00:00",
    };
  };

  paginationUpdated(itemsPerTable, currentPage) {
    const historyLogs = this.state.historyLogs;
    historyLogs.itemsPerPage = itemsPerTable;
    historyLogs.page = currentPage;

    this.setState({ historyLogs }, () => {
      this.GetLoadDataHistory();
    });
  }

  UploadFile = (event) => {
    event.preventDefault();
    this.setState({ showOverlayLoader: true });

    if (this.state.fileToUpload !== null && this.state.fileToUpload !== "") {
      const url = endpoints.loadDataFiles[this.state.fileTypeToUpload];
      const file = this.state.fileToUpload;
      let data = new FormData();
      data.append("file", file);

      return fetch(url, {
        method: "post",
        body: data,
        headers: {
          Authorization: `Bearer ${cookies.get("rawtoken")}`,
        },
      }).then((response) => {
        this.setState({ showOverlayLoader: false });
        if (response.status >= 200 && response.status < 300) {
          toast.success("Success");
        } else {
          response.json().then(function (object) {
            toast.error(object.message);
          });
        }
        this.GetLoadDataHistory();
      });
    }
  };

  public render() {
    const LoaderWrapper = styled.div`
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      position: fixed;
      min-height: 300px;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.15);
      z-index: 12;
    `;
    return (
      <>
        <Form onSubmit={(event) => this.UploadFile(event)}>
          <FormGroup>
            <Row>
              <Col md={{ size: 6, offset: 3 }}>
                <Label className="mb-3" for="fileName">
                  {i18next.t("choose_file")}
                </Label>
                <Input
                  className="mb-3"
                  type="select"
                  id="fileName"
                  value={this.context.fileTypeToUpload}
                  onChange={(e) =>
                    this.setState({ fileTypeToUpload: e.target.value })
                  }
                >
                  {Object.keys(excelFileTypes).map(function (key) {
                    return (
                      <option value={key} key={key}>
                        {excelFileTypes[key].label}
                      </option>
                    );
                  })}
                </Input>

                <CustomInput
                  className="mb-3"
                  type="file"
                  id="fileUpload"
                  label={i18next.t("upload_file")}
                  onChange={(e) =>
                    this.setState({ fileToUpload: e.target.files[0] })
                  }
                />
                <Button type="submit" className="w-100">
                  {i18next.t("upload")}
                </Button>
              </Col>
            </Row>
          </FormGroup>
        </Form>
        <Table
          type="history_log"
          title={i18next.t("history_log")}
          tableSettings={this.getHistoryLogsSettings()}
          data={this.state.historyLogs.data}
          allowMultipleSelection={false}
          paginationUpdated={this.paginationUpdated}
          emitSelectedIds={() => null}
          totalItems={
            this.state.historyLogs.totalSize
              ? this.state.historyLogs.totalSize
              : 0
          }
          currentPage={this.state.historyLogs.page}
          itemsPerPage={this.state.historyLogs.itemsPerPage}
          isLoading={this.state.historyLogs.isLoading}
          selectedRows={[]}
          filters={this.state.historyFilters}
          // currentSelectedFilter={this.state.hermesOrderBy}
          currentSortingDirection={this.state.historyLogs.orderDirection}
          //emitExportData={this.emitExportHermesData}
          hideFilters={true}
          hideExport={true}
          hideSearch={true}
          hideFooter={false}
        />
        {this.state.showOverlayLoader === true && (
          <LoaderWrapper>
            <p>{i18next.t("uploading")}</p>
            <img src={loader} alt="" />
          </LoaderWrapper>
        )}
      </>
    );
  }
}

export default connect()(DataLoad);
