import { checkHttpStatus, parseJSON, DEFAULT_HEADERS } from '../Utils/actions';
import Cookies from 'universal-cookie';
import * as endpoints from '../constants/reportsEndpoints/reportsEndpoints'
import FileSaver from 'file-saver';

const cookies = new Cookies();

export function getRegionsAndStocksReports(callback, error) {
    //TODO CHANGE URL
    var data = {};
    return fetch(endpoints.GET_DEPOTS_REGIONS_ENDPOINT, {
        method: 'get',
        headers: {'Accept': '*/*','Content-Type': 'application/json-patch+json','Authorization': `Bearer ${cookies.get('rawtoken')}`}
    })
        .then(checkHttpStatus)
        .then(parseJSON)
        .then(response => {
            try {
                data["regions"] = response;
                getGeneratedStocksReports(callback, error, data)
            } catch (e) {
                error(e);
            }
        })
        .catch(e => {
            error(e);
        })
}

export function getDepotsForRegion(region, callback, error) {
    var url = endpoints.GET_DEPOTS_FOR_REGION_ENDPOINT + '?region=' + region;

    return fetch(url, {
        method: 'get',
        headers: {'Accept': '*/*','Content-Type': 'application/json-patch+json','Authorization': `Bearer ${cookies.get('rawtoken')}`}
    })
        .then(checkHttpStatus)
        .then(parseJSON)
        .then(response => {
            try {
                callback(response);
            } catch (e) {
                error(e);
            }
        })
        .catch(e => {
            error(e);
        })
}

export function getGeneratedStocksReports(callback, error, data) {
    //TODO CHANGE URL
    var url = endpoints.GENERATE_STOCKS_REPORT_ENDPOINT;

    return fetch(url, {
        method: 'get',
        headers: {'Accept': '*/*','Content-Type': 'application/json-patch+json','Authorization': `Bearer ${cookies.get('rawtoken')}`}
    })
        .then(checkHttpStatus)
        .then(parseJSON)
        .then(response => {
            try {
                if (data) {
                    data["generatedReportsList"] = response;
                    callback(data);
                }
                else {
                    callback(response);
                }
            } catch (e) {
                error(e);
            }
        })
        .catch(e => {
            error(e);
        })
}

export function generateStocksReportForDepots(depotsData, callback, error) {
    var generateSalesReportUrl = endpoints.GENERATE_STOCKS_REPORT_ENDPOINT,
        parseFunc = depotsData.reportType === 'preview' ? parseJSON : '';

    return fetch(generateSalesReportUrl, {
        method: 'POST',
        headers: {'Accept': '*/*','Content-Type': 'application/json-patch+json','Authorization': `Bearer ${cookies.get('rawtoken')}`},
        body: JSON.stringify(depotsData)
    })
        .then(checkHttpStatus)
        .then(parseFunc)
        .then(response => {
            callback(response);
        })
        .catch(e => {
            error(e);
        })
}

export function downloadStocksReport(depotsData, callback, error) {
    var url = endpoints.GENERATE_STOCKS_REPORT_ENDPOINT,
        fileName = '';

    return fetch(url, {
        method: 'post',
        headers: {'Accept': '*/*','Content-Type': 'application/json-patch+json','Authorization': `Bearer ${cookies.get('rawtoken')}`},
        body: JSON.stringify(depotsData)
    })
        .then(checkHttpStatus)
        .then(function (response) {
            var x = response.headers
                .get("content-disposition");
            fileName = response.headers
                .get("content-disposition")
                .split(';')[1]
                .split('=')[1];
            return response.blob();
        }).then(function (blob) {
            try {
                FileSaver.saveAs(blob, fileName);
                callback();
            } catch (e) {
                error(e);
            }
        })
        .catch(e => {
            error(e);
        })
}
