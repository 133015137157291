import { checkHttpStatus, parseJSON, DEFAULT_HEADERS } from '../Utils/actions';
import jwtDecode from 'jwt-decode';
import Cookies from 'universal-cookie';
import * as endpoints from '../constants/endpoints'
import * as reportsEndpoints from '../constants/reportsEndpoints/reportsEndpoints'
import * as roles from '../Utils/actions'

const cookies = new Cookies();
// asd
export function loginUser(userCredentials, callback, error) {
    return fetch(endpoints.LOGIN_USER_ENDPOINT, {
        method: 'post',
        headers: {'Accept': '*/*','Content-Type': 'application/json-patch+json','Authorization': `Bearer ${cookies.get('rawtoken')}`},
        body: JSON.stringify({ username: userCredentials.username, password: userCredentials.password })
    })
        .then(checkHttpStatus)
        .then(response => {
            try {
                var token = response.headers.get('X-JWToken');
                let decoded = jwtDecode(token);
                //set cookie
                let exp = decoded["exp"];
                exp = new Date(exp * 1000);
                exp.setTime(exp.getTime());
                cookies.set('token', decoded, { path: '/', expires: exp });
                cookies.set('rawtoken', token, { path: '/', expires: exp });
                callback();
            } catch (e) {
                error(e);
            }
        })
        .catch(e => {
            error(e);
        })
}

export function sendVerificationCode(userCredentials, callback, error) {
    return fetch(endpoints.SEND_VERIFICATION_CODE, {
        method: 'post',
        headers: {'Accept': '*/*','Content-Type': 'application/json-patch+json','Authorization': `Bearer ${cookies.get('rawtoken')}`},
        body: JSON.stringify({ email: userCredentials.email })
    })
        .then(checkHttpStatus)
        .then(() => {
            try {
                callback();
            } catch (e) {
                error(e);
            }
        })
        .catch(e => {
            error(e);
        })
}

export function changePassword(userCredentials, callback, error) {
    return fetch(endpoints.CHANGE_PASSWORD, {
        method: 'post',
        headers: {'Accept': '*/*','Content-Type': 'application/json-patch+json','Authorization': `Bearer ${cookies.get('rawtoken')}`},
        body: JSON.stringify({ email: userCredentials.email, resetPasswordCode: userCredentials.resetPasswordCode, newPassword: userCredentials.newPassword })
    })
        .then(checkHttpStatus)
        .then(() => {
            try {
                callback();
            } catch (e) {
                error(e);
            }
        })
        .catch(e => {
            error(e);
        })
}

export function getAction(type, callback, error) {
    var url;
    switch (type) {
        case 'allUsers': url = endpoints.GET_USERS_FOR_TENANT_ADMIN_ENDPOINT;
            break;
        case 'adminTenantAdmin': url = endpoints.GET_USERS_FOR_ADMIN_ENDPOINT;
            break;
        case 'tenants': url = endpoints.MANAGE_TENANTS_ENDPOINT;
            break;
        case 'distributors': url = endpoints.MANAGE_DISTRIBUTORS_ENDPOINT;
            break;
    }
    return fetch(url, {
        method: 'get',
        headers: {'Accept': '*/*','Content-Type': 'application/json-patch+json','Authorization': `Bearer ${cookies.get('rawtoken')}`}
    })
        .then(checkHttpStatus)
        .then(parseJSON)
        .then(response => {
            try {
                callback(response);
            } catch (e) {
                error(e);
            }
        })
        .catch(e => {
            error(e);
        })
}

export function editUser(userCredentials, callback, error) {
    var url = endpoints.userEditUrlByRoleId[userCredentials.roleId],
        method = 'put';
    if (userCredentials.roleId === 1) {
        method = 'post';
    }
    url += userCredentials.userId;
    return fetch(url, {
        method: method,
        headers: {'Accept': '*/*','Content-Type': 'application/json-patch+json','Authorization': `Bearer ${cookies.get('rawtoken')}`},
        body: JSON.stringify(userCredentials)
    })
        .then(checkHttpStatus)
        .then(() => {
            try {
                if (Number(userCredentials.roleId) === 4 || Number(userCredentials.roleId) === 3) {
                    associateDepotsToSupervisor(userCredentials.userId, userCredentials.tenantId, userCredentials.selectedDepots, callback, error);
                }
                else {
                    callback();
                }
            } catch (e) {
                if (Number(userCredentials.roleId) === 4 || Number(userCredentials.roleId) === 3) {
                    associateDepotsToSupervisor(userCredentials.userId, userCredentials.tenantId, userCredentials.selectedDepots, callback, error);
                }
                error(e);
            }
        })
        .catch(e => {
            if (Number(userCredentials.roleId) === 4 || Number(userCredentials.roleId) === 3) {
                associateDepotsToSupervisor(userCredentials.userId, userCredentials.tenantId, userCredentials.selectedDepots, callback, error);
            }
            error(e);
        })
}

function associateDepotsToSupervisor(userId, tenantId, selectedDepots, callback, error) {
    var url = endpoints.ASSOCIATE_DEPOTS_TO_TENANT_SUPERVISOR_ENDPOINT,
        requestBody = {
            userIdx: userId,
            tenantIdx: tenantId,
            depotIds: selectedDepots
        },
        method = 'post';

    return fetch(url, {
        method: method,
        headers: {'Accept': '*/*','Content-Type': 'application/json-patch+json','Authorization': `Bearer ${cookies.get('rawtoken')}`},
        body: JSON.stringify(requestBody)
    })
        .then(checkHttpStatus)
        .then(() => {
            try {
                callback();
            } catch (e) {
                error(e);
            }
        })
        .catch(e => {
            error(e);
        })
}

export function addUser(userCredentials, callback, error) {
    var url = endpoints.userEditUrlByRoleId[userCredentials.roleId],
        method = 'post',
        roleId = Number(userCredentials.roleId);
    if (roleId === 1 || roleId === 3) {
        method = 'put';
    }
    return fetch(url, {
        method: method,
        headers: {'Accept': '*/*','Content-Type': 'application/json-patch+json','Authorization': `Bearer ${cookies.get('rawtoken')}`},
        body: JSON.stringify(userCredentials)
    })
        .then(checkHttpStatus)
        .then(parseJSON)
        .then(createdUser => {
            try {
                if (Number(userCredentials.roleId) === 4 || Number(userCredentials.roleId) === 3) {
                    associateDepotsToSupervisor(createdUser.userId, createdUser.tenantId, userCredentials.selectedDepots, callback, error);
                }
                else {
                    callback();
                }
            } catch (e) {
                error(e);
            }
        })
        .catch(e => {
            error(e);
        })
}
function performGetAction(url, error, callback, data, type) {
    return fetch(url, {
        method: 'get',
        headers: {'Accept': '*/*','Content-Type': 'application/json-patch+json','Authorization': `Bearer ${cookies.get('rawtoken')}`}
    })
        .then(checkHttpStatus)
        .then(parseJSON)
        .then(response => {
            try {
                data[type] = response;
                callback(data);
            } catch (e) {
                error('could not load ' + type);
                callback(data);
            }
        })
        .catch(e => {
            error('could not load ' + type);
            callback(data);
        })
}

export function attemptEdit(selectedUserId, callback, error) {
    var data = {},
        role = roles.getUserRole(),
        url = role === 'Administrator' ? endpoints.GET_USERS_FOR_ADMIN_ENDPOINT : endpoints.GET_USERS_FOR_TENANT_ADMIN_ENDPOINT;
    //load depots only if user is TenantSupervisor
    if (role === 'Administrator') {
        var selectedUser = performGetAction(url + selectedUserId, error, callback, data, 'selectedUser');
    }
    else {
        var selectedDepots = (data) => getTenantSupervisorDepots(selectedUserId, error, callback, data, 'selectedDepots');
        var depots = (data) => getDepotsByRegion('west', error, selectedDepots, data, 'depots');
        var distributors = (data) => performGetAction(endpoints.MANAGE_DISTRIBUTORS_ENDPOINT, error, depots, data, 'distributors');
        var selectedUser = performGetAction(url + selectedUserId, error, distributors, data, 'selectedUser');
    }
}

export function getTenantSupervisorDepots(userId, error, callback, data, type) {
    var url = endpoints.GET_DEPOTS_FOR_TENANT_SUPERVISOR_ENDPOINT + userId;

    return fetch(url, {
        method: 'get',
        headers: {'Accept': '*/*','Content-Type': 'application/json-patch+json','Authorization': `Bearer ${cookies.get('rawtoken')}`}
    })
        .then(checkHttpStatus)
        .then(parseJSON)
        .then(response => {
            try {
                if (data && type) {
                    data[type] = response;
                    callback(data);
                }
                else {
                    callback(response);
                }
            } catch (e) {
                error('could not load ' + type);
                callback(data);
            }
        })
        .catch(e => {
            error('could not load ' + type);
            callback(data);
        })
}

export function getDepotsByRegion(region, error, callback, data, type) {
    var url = region ? reportsEndpoints.GET_DEPOTS_FOR_REGION_ENDPOINT + '?region=' + region
        : reportsEndpoints.GET_DEPOTS_FOR_REGION_ENDPOINT;

    return fetch(url, {
        method: 'get',
        headers: {'Accept': '*/*','Content-Type': 'application/json-patch+json','Authorization': `Bearer ${cookies.get('rawtoken')}`}
    })
        .then(checkHttpStatus)
        .then(parseJSON)
        .then(response => {
            try {
                if (data && type) {
                    data[type] = response;
                    callback(data);
                }
                else {
                    callback(response);
                }
            } catch (e) {
                error('could not load ' + type);
                callback(data);
            }
        })
        .catch(e => {
            error('could not load ' + type);
            callback(data);
        })
}

export function changeAuthenticated(authenticated, callback, error) {
    try {
        if (!authenticated) {
            cookies.remove('token');
            cookies.remove('rawtoken');
        }
        callback();
    } catch (e) {
        error(e);
    }
}

export function deleteUser(userId, callback, error) {
    var url = roles.getUserRole() === 'Administrator' ? endpoints.GET_USERS_FOR_ADMIN_ENDPOINT : endpoints.GET_USERS_FOR_TENANT_ADMIN_ENDPOINT;
    url += userId;

    return fetch(url, {
        method: 'delete',
        headers: {'Accept': '*/*','Content-Type': 'application/json-patch+json','Authorization': `Bearer ${cookies.get('rawtoken')}`}
    })
        .then(checkHttpStatus)
        .then(response => {
            try {
                callback();
            } catch (e) {
                error(e);
            }
        })
        .catch(e => {
            error(e);
        })
}

export function attemptAdd(callback, error) {
    var url = endpoints.MANAGE_DISTRIBUTORS_ENDPOINT,
        method = 'get';

    return fetch(url, {
        method: method,
        headers: {'Accept': '*/*','Content-Type': 'application/json-patch+json','Authorization': `Bearer ${cookies.get('rawtoken')}`}
    })
        .then(checkHttpStatus)
        .then(parseJSON)
        .then(response => {
            try {
                callback(response);
            } catch (e) {
                error(e);
            }
        })
        .catch(e => {
            error(e);
        })
}
