import * as React from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { ApplicationState } from "../../store";
import * as WHSDataReportStore from "../../store/WHSDataReportReducer";
import FilterableTableContainer from "../Helpers/FilterableTableContainer";
import { RouteComponentProps } from "react-router";
import Select from "../Select";
import Button from "../Button";
import $ from "jquery";
import loader from "../../images/spinner.gif";
import i18next from "i18next";

import regions from "../../regions.json";

const years = [
  "2018",
  "2019",
  "2020",
  "2021",
  "2022",
  "2023",
  "2024",
  "2025",
  "2026",
  "2027",
  "2028",
  "2029",
  "2030",
];
const months = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12"];

type WHSDataReportProps = WHSDataReportStore.WHSDataReportState &
  typeof WHSDataReportStore.actionCreators &
  RouteComponentProps<{}>;

class WHSDataReport extends React.PureComponent<WHSDataReportProps> {
  state = {
    shouldRender: false,
    selectedRegion: "-1",
    selectedMonth: "-1",
    selectedYear: "-1",
    isTableVisible: false,
    tableProps: {
      // Data for the table to display; can be anything
      data: [],
      // Fields to show in the table, and what object properties in the data they bind to
      fields: [],
      headerVisible: true,
      tableName: "WHS Data Report",
      namespace: "WHSDataReport",
      initialSort: "depot",
      bsLength: 12,
      trClassName: "clickable-tr whs-data-report-tr",
      pageSize: 100000,
    },
    isFilterBtnDisabled: false,
    isLoaderVisible: false,
  };

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.props.clearValues();
    if (
      this.props.selectedMonth === "-1" ||
      this.props.selectedRegion === "-1" ||
      this.props.selectedYear === "-1"
    ) {
      this.toggleMapBtnState(true);
    }
    //this.loadDepotsForSelectedData();
  }

  componentDidUpdate() {
    if (
      this.props.selectedMonth === "-1" ||
      this.props.selectedRegion === "-1" ||
      this.props.selectedYear === "-1"
    ) {
      this.toggleMapBtnState(true);
    }
  }

  public onChange = (event) => {
    this.props.changeValue(event.target.name, event.target.value);
    if (
      this.props.selectedMonth !== "-1" &&
      this.props.selectedRegion !== "-1" &&
      this.props.selectedYear !== "-1"
    ) {
      this.toggleMapBtnState(false);
    }
    event.preventDefault();
  };

  loadDepotsForSelectedData = () => {
    this.setState({
      isFilterBtnDisabled: true,
      isLoaderVisible: true,
    });
    var selectedData = {
      month: this.props.selectedMonth,
      year: this.props.selectedYear,
      regions: [this.props.selectedRegion],
    };
    let callback = () => {
      this.setState({
        tableProps: this.getDepotsTableProps(),
        isTableVisible: true,
        isLoaderVisible: false,
        isFilterBtnDisabled: false,
      });
    };
    this.props.getDepotsForSelectedData(selectedData, callback);
  };

  getDaysInMonth = (month, year) => {
    return new Date(year, month, 0).getDate();
  };

  salesRender = (props) => {
    let sales = props.value;

    if (sales === true) {
      return <div className="raw-data-report-green"></div>;
    }

    if (sales === false) {
      return <div className="raw-data-report-red"></div>;
    }

    return "";
  };

  depotRender = (props) => {
    let depot = props.value;

    return <div className="raw-data-report-depot">{depot}</div>;
  };

  getDepotsTableProps = () => {
    //TODO assign columns accordingly
    var fields = [
        {
          name: "depotName",
          displayName: i18next.t("depot"),
          render: null,
          tdClassName: "",
        },
      ],
      depotsList = this.props.depotsList,
      daysInMonth = this.getDaysInMonth(
        this.props.selectedMonth,
        this.props.selectedYear,
      ),
      dataSave = [];
    var newEl = {},
      count = 0;
    for (let data in depotsList) {
      data = depotsList[data];
      var day = new Date(data["dayOfTheMonth"]).getDate().toString();
      if (count == daysInMonth) {
        dataSave.push(newEl);
        newEl = {};
        count = 0;
      }
      if (count < daysInMonth && fields.length < daysInMonth + 1) {
        fields.push({
          name: day,
          displayName: day,
          render: this.salesRender,
          tdClassName: "whs-data-td",
        });
      }
      newEl["depotName"] = data["depotName"];
      newEl[day] = data["sale"];
      count++;
    }
    // fix for last item
    if (depotsList.length >= 1) {
      dataSave.push(newEl);
    }

    fields[0].render = this.depotRender;
    return {
      // Data for the table to display; can be anything
      //TODO Change to depotData
      data: dataSave,
      // Fields to show in the table, and what object properties in the data they bind to
      //TODO Change to fields
      fields: fields,
      headerVisible: true,
      tableName: "WHS Data Report",
      namespace: "WHSDataReport",
      initialSort: "depot",
      bsLength: 12,
      trClassName: "clickable-tr whs-data-report-tr",
      pageSize: 100000,
    };
  };

  toggleMapBtnState = (state) => {
    //edit to set editability
    var loadDepots = document.getElementById("load-depots");
    if (loadDepots) {
      (loadDepots as HTMLInputElement).disabled = state;
    }
  };

  resetBtnState() {
    let buttons = document.getElementsByTagName("button");
    for (let i = 0; i < buttons.length; i++) {
      buttons[i].disabled = false;
    }
  }

  public render() {
    const TableHead = styled.div`
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-end;

      > * {
        margin: 0 8px;

        &:first-child {
          margin-left: 0;
        }

        &:last-child {
          margin-right: 0;
        }
      }
    `;

    TableHead.Field = styled.div`
      width: ${({ size }) => (size === "large" ? "200px" : "120px")};
    `;

    TableHead.Button = styled.div`
      position: relative;
    `;

    const { isLoading } = this.props;

    return (
      <div className="whs-data-report">
        <TableHead>
          <TableHead.Field size="large">
            <Select
              label="Selected Region"
              name="selectedRegion"
              value={this.props.selectedRegion}
              defaultValue={"-1"}
              onChange={this.onChange}
              options={
                regions
                  ? regions.map((region, id) => {
                      return {
                        value: region.name,
                        label: region.name,
                      };
                    })
                  : []
              }
            />
          </TableHead.Field>

          <TableHead.Field>
            <Select
              label="Selected Month"
              name="selectedMonth"
              value={this.props.selectedMonth}
              defaultValue={"-1"}
              onChange={this.onChange}
              options={
                months
                  ? months.map((month, id) => {
                      return {
                        value: month,
                        label: month,
                      };
                    })
                  : []
              }
            />
          </TableHead.Field>

          <TableHead.Field>
            <Select
              label="Selected Year"
              name="selectedYear"
              value={this.props.selectedYear}
              defaultValue={"-1"}
              onChange={this.onChange}
              options={
                years
                  ? years.map((year, id) => {
                      return {
                        value: year,
                        label: year,
                      };
                    })
                  : []
              }
            />
          </TableHead.Field>
          <TableHead.Button>
            <Button
              id="load-depots"
              size="large"
              onClick={this.loadDepotsForSelectedData}
              disabled={this.state.isFilterBtnDisabled}
            >
              Filter
            </Button>
          </TableHead.Button>
        </TableHead>

        {this.state.isLoaderVisible ? (
          <div
            className="d-flex align-items-center justify-content-center"
            style={{ minHeight: "300px" }}
          >
            <img src={loader} alt="" />
          </div>
        ) : null}

        {this.state.isTableVisible ? (
          <FilterableTableContainer
            {...{ isLoading }}
            tableProps={this.state.tableProps}
          />
        ) : null}
      </div>
    );
  }
}
export default connect(
  (state: ApplicationState) => state.whsDataReports,
  WHSDataReportStore.actionCreators,
)(WHSDataReport);
