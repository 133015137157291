import * as React from "react";
import styled from "styled-components";
import * as AuthenticationStore from "../store/AuthenticationReducer";
import { ApplicationState } from "../store";
import { connect } from "react-redux";
import { Container } from "react-grid-system";
import NavMenu from "./NavMenu";

const Wrapper = styled.div`
  padding: 40px 0 40px 0;

  ${({ theme }) => `
      @media ${theme.mediaQueries.smUp} {
        padding-left: 40px;
        padding-right: 40px;
      }
    `}
`;

const Layout = (props: {
  children?: React.ReactNode;
  isAuthenticated: boolean;
}) => {
  return (
    <React.Fragment>
      {props.isAuthenticated ? (
        <>
          <NavMenu />
          <Wrapper>
            <Container>{props.children}</Container>
          </Wrapper>
        </>
      ) : (
        props.children
      )}
    </React.Fragment>
  );
};

export default connect(
  (state: ApplicationState) => state.authentication,
  AuthenticationStore.actionCreators
)(Layout);
