import React from "react";

import { Flex } from "antd";
import i18next from "i18next";

import { BSCoreServicesDtoMarketplaceOrderTotalDto } from "api/reactQuery/querySchemas";
import Card from "components/Card/Card";

const OrderTotals: React.FC<{
  totals: BSCoreServicesDtoMarketplaceOrderTotalDto;
  isTruckAreaFactorInLimit?: boolean;
  isTotalWeightInLimit?: boolean;
}> = ({ totals, isTruckAreaFactorInLimit, isTotalWeightInLimit }) => {
  return (
    <Flex vertical style={{ width: "100%" }}>
      <Flex style={{ width: "100%" }} justify="space-between">
        <Card
          title={i18next.t("ttl_truck_area_factor")}
          hasError={
            isTruckAreaFactorInLimit === undefined
              ? false
              : !isTruckAreaFactorInLimit
          }
          value={totals.ttlTruckAreaFactor}
          isBlueCard
        />
        <Card title={i18next.t("ttl_pallets")} value={totals.ttlPallets} />
        <Card
          title={i18next.t("ttl_pallet_count_industrial")}
          value={totals.ttlPalletCountIndustrialType}
        />
        <Card
          title={i18next.t("ttl_pallet_count_licensed“")}
          value={totals.ttlPalletCountLicensedType}
        />
        <Card
          title={i18next.t("ttl_weight_limit")}
          hasError={
            isTotalWeightInLimit === undefined ? false : !isTotalWeightInLimit
          }
          value={totals.ttlWeight}
          isBlueCard
        />
      </Flex>
      <Flex style={{ marginTop: 16, width: "100%" }} justify="space-between">
        <Card
          title={i18next.t("ttl_volume_in_hl")}
          value={totals.ttlVolumeInHL}
        />
        <Card
          title={i18next.t("ttl_beer_cost_wt_vat_in_bgn")}
          value={totals.ttlBeerCostWithoutVATBGN}
        />
        <Card
          title={i18next.t("ttl_beer_cost_w_vat_in_bgn")}
          value={totals.ttlBeerCostWithVATBGN}
        />
        <Card
          title={i18next.t("ttl_packaging_cost_in_bgn")}
          value={totals.ttlPackagingCostInBGN}
        />
        <Card
          title={i18next.t("ttl_beer_w_vat_packaging_cost_in_bgn")}
          value={totals.ttlBeerWithVATAndPackagingCostInBGN}
        />
      </Flex>
    </Flex>
  );
};
export default OrderTotals;
